import { Modal, ModalBody } from "reactstrap";
import { ModalButtonSection } from "../../styledComponents/members";
import { CommonText } from "../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../styledComponents/buttons";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import { useEffect, useState } from "react";

const ProjectDeleteModal = ({ isOpen, toggle, handleDelete, isLoading }) => {
  const [apiInCall, setApiInCall] = useState(false);
  useEffect(() => {
    if (isLoading) setApiInCall(true);
    else if (isLoading === false && apiInCall === true) {
      setTimeout(() => {
        setApiInCall(false);
      }, 300);
    }
  }, [isLoading]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `33vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          {`Are you sure you want to delete this project? All the data related to this project will be deleted.`}
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            type="delete"
            padding={isLoading && "0 24px"}
            onClick={() => !apiInCall && handleDelete()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              "Delete Project"
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ProjectDeleteModal;
