import { useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import createProjectDropdown from "../../components/DropdownStyle/CreateProjectDropdown";
import {
  Container,
  CommonText,
  RoundLetterSection,
  TableText,
  AssigneeMoreText,
  StyledLink,
  ResendInvite,
  CommonFlex,
  FirstWordRound,
} from "../../styledComponents/common";
import { TableItem, AssignedProjectBox } from "../../styledComponents/teams";
import {
  MembersTableHeadContainer,
  MembersTableContainer,
  TeamSection,
  InviteHeadSection,
  InviteTableContainer,
  InviteProjectSection,
  MemberNameDiv,
  InviteLink,
} from "../../styledComponents/members";
import {
  CommImageNameComp,
  CommImageInviteComp,
  CommFirstRoundLetterComp,
  ApprovalSettingsSection,
} from "./CommonComponents";

import { currencySymbolMap } from "../../utils/currencies";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import ProjectListPopup from "../../components/ProjectListPopup/ProjectListPopup";

import { getFullName } from "../../utils/helper";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";

const MembersTable = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [invitedMemberList, setInvitedMemberList] = useState([]);
  const [hoveredTeam, setHoveredTeam] = useState(null);
  const [memberProjectList, setMemberProjectList] = useState(null);
  const [memberTeamList, setMemberTeamList] = useState(null);
  const [invitedMemberIdForProject, setInvitedMemberIdForProject] =
    useState(null);
  const [invitedMemberIdForTeam, setInvitedMemberIdForTeam] = useState(null);

  const actionOptions = [
    { value: "edit", label: "Edit Member" },
    { value: "remove", label: "Remove Member" },
  ];
  const inviteActionOptions = [
    { value: "resend", label: "Resend Invitation" },
    { value: "delete", label: "Delete Invitation" },
  ];

  useEffect(() => {
    if (props.membersList && props.membersList.length > 0) {
      const sortedMembers = [...props.membersList].sort((a, b) => {
        const nameA = getFullName(a.user).toLowerCase();
        const nameB = getFullName(b.user).toLowerCase();
        return nameA.localeCompare(nameB);
      });
      const list = sortedMembers.map((member) => ({
        id: member.id,
        userId: member.user.id,
        name: getFullName(member.user),
        avatar: member.user.avatar || null,
        color: member.user.color || null,
        role: member.role,
        teams: member.teams.sort((a, b) => a.name.localeCompare(b.name)),
        assignedProjects: member.projects.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        payment: member.payment
          ? `${currencySymbolMap[member.payment.currency]} ${(
              member.payment.pay_rate / 100
            ).toFixed(2)} / hour`
          : "",
        pay_rate: member.payment?.pay_rate,
        bill_rate: member.payment?.bill_rate,
        requireTimesheetApproval: member.require_timesheet_approval,
        limitTracking: member.limit_tracking,
        weeklyLimit: member.weekly_tracking_limit,
      }));

      setMemberList([...list]);
    } else if (props.membersList && props.membersList.length === 0) {
      setMemberList([]);
    }
  }, [props.membersList]);

  useEffect(() => {
    if (props.invitedMembersList && props.invitedMembersList.length > 0) {
      let list = [];
      props.invitedMembersList.map((member) => {
        list.push({
          id: member.id,
          email: member.email,
          role: member.role,
          assignedProjects: member.projects.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
          assignedTeams: member.teams.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
        });
      });
      setInvitedMemberList(list);
    } else if (
      props.invitedMembersList &&
      props.invitedMembersList.length === 0
    ) {
      setInvitedMemberList([]);
    }
  }, [props.invitedMembersList]);

  const onActionChange = (e, memberId) => {
    if (!memberId) return;
    if (e.value === "edit") {
      props.history.push(`/user/member-details?id=${memberId}`);
    } else if (e.value === "remove") {
      props.toggleDeleteModal(memberId);
    }
  };

  const onInviteActionChange = (e, invitation) => {
    if (e.value === "delete") {
      props.toggleInvitationDeleteModal(invitation.id);
    } else if (e.value === "resend") {
      resendMemberInvitation(invitation);
    }
  };

  const updateTimesheetApprovalSettings = (memberId, value) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      memberId
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        settings_id: memberId,
        require_timesheet_approval: value,
      };
      props.updateMemberwiseTimesheetApprovalSettings(payload);
    }
  };

  const resendMemberInvitation = (invitation) => {
    if (invitation) {
      const projectList = [];
      const teamList = [];
      if (
        invitation.assignedProjects &&
        invitation.assignedProjects.length > 0
      ) {
        invitation.assignedProjects.map((project) => {
          projectList.push(project.id);
        });
      }
      if (invitation.assignedTeams && invitation.assignedTeams.length > 0) {
        invitation.assignedTeams.map((team) => {
          teamList.push(team.id);
        });
      }
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          emails: [invitation.email],
          project_ids: projectList,
          team_ids: teamList,
          resend: true,
        };
        props.inviteMember(payload);
      }
    }
  };

  return (
    <Container style={{ paddingBottom: `20px` }}>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : props.tabSelect === "active" ? (
        <>
          {props.membersList && props.membersList.length > 0 && (
            <>
              <MembersTableHeadContainer
                style={{ borderBottom: `1px solid #C2CCE1` }}
              >
                <TableItem>
                  <TableText>Member Name</TableText>
                </TableItem>
                {/* <TableItem>
              <TableText>Role</TableText>
            </TableItem> */}
                {/* <TableItem>
              <TableText>Teams</TableText>
            </TableItem> */}
                <TableItem centerContent>
                  <TableText>Teams</TableText>
                </TableItem>
                <TableItem centerContent style={{ marginRight: "40px" }}>
                  <TableText>Projects</TableText>
                </TableItem>
                <TableItem style={{ marginLeft: "30px" }}>
                  <TableText>Payment</TableText>
                </TableItem>
                <TableItem>
                  <TableText>Weekly Limit</TableText>
                </TableItem>
                {props.selectedOrganization &&
                  props.selectedOrganization.role !== "member" && (
                    <TableItem>
                      <TableText>Actions</TableText>
                    </TableItem>
                  )}
              </MembersTableHeadContainer>
              {memberList.map((member, index) => (
                <MembersTableContainer key={index}>
                  <TableItem
                    onClick={() =>
                      member.userId &&
                      props.history.push(
                        `/user/member-details?id=${member.userId}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <CommImageNameComp
                      imgSource={member.avatar}
                      name={member.name}
                      color={member.color}
                      size="36px"
                      index={index}
                      hoverUnderline
                    />
                  </TableItem>

                  <TableItem centerContent>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                      }}
                      onMouseEnter={() => {
                        if (member.teams.length > 0) {
                          setMemberTeamList(member.id);
                        }
                      }}
                      onMouseLeave={() => setMemberTeamList(null)}
                    >
                      <AssigneeMoreText fontSize="13px">
                        {member.teams.length}
                      </AssigneeMoreText>
                      {memberTeamList &&
                        memberTeamList === member.id &&
                        member.teams.length > 0 && (
                          <ProjectListPopup projectList={member.teams} />
                        )}
                    </div>
                  </TableItem>

                  <TableItem centerContent style={{ marginRight: "40px" }}>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                      }}
                      onMouseEnter={() => {
                        if (member.assignedProjects.length > 0) {
                          setMemberProjectList(member.id);
                        }
                      }}
                      onMouseLeave={() => setMemberProjectList(null)}
                    >
                      <AssigneeMoreText fontSize="13px">
                        {member.assignedProjects.length}
                      </AssigneeMoreText>
                      {memberProjectList &&
                        memberProjectList === member.id &&
                        member.assignedProjects.length > 0 && (
                          <ProjectListPopup
                            projectList={member.assignedProjects}
                          />
                        )}
                    </div>

                    {/* )} */}
                  </TableItem>

                  <TableItem style={{ marginLeft: "30px" }}>
                    <TableText name>
                      {!member.pay_rate && !member.bill_rate
                        ? "No pay/bill rate set"
                        : member.payment}
                    </TableText>
                    {!member.pay_rate &&
                      !member.bill_rate &&
                      props.selectedOrganization &&
                      props.selectedOrganization.role !== "member" && (
                        <ResendInvite
                          margin="3px 0 0 0"
                          onClick={() =>
                            member.userId &&
                            props.history.push(
                              `/user/member-details?id=${member.userId}`
                            )
                          }
                        >
                          Set Pay Rate
                        </ResendInvite>
                      )}
                    <ApprovalSettingsSection
                      memberId={member.id}
                      active={member.requireTimesheetApproval}
                      handleChange={updateTimesheetApprovalSettings}
                      isAdmin={
                        props.selectedOrganization &&
                        props.selectedOrganization.role !== "member"
                      }
                    />
                  </TableItem>
                  <TableItem>
                    <TableText
                      name={member.limitTracking && member.weeklyLimit}
                      fontWeight="500"
                    >
                      {member.limitTracking && member.weeklyLimit
                        ? `${Math.floor(member.weeklyLimit / 3600)} Hours`
                        : "No Limit Set"}
                    </TableText>
                  </TableItem>
                  {props.selectedOrganization &&
                    props.selectedOrganization.role !== "member" && (
                      <TableItem>
                        <Select
                          isSearchable={false}
                          value={null}
                          options={
                            props.selectedOrganization.role === "team_leader"
                              ? actionOptions.filter(
                                  (item) => item.value !== "remove"
                                )
                              : actionOptions
                          }
                          placeholder="Actions"
                          onChange={(e) => {
                            onActionChange(e, member.userId);
                          }}
                          styles={createProjectDropdown({ menuWidth: "145px" })}
                        />
                      </TableItem>
                    )}
                </MembersTableContainer>
              ))}
            </>
          )}
          {props.membersList && props.membersList.length <= 0 && (
            <NoDataComponent title="No member found with this name!" />
          )}
        </>
      ) : (
        <>
          {invitedMemberList && invitedMemberList.length > 0 && (
            <>
              <InviteHeadSection>
                <TableItem>
                  <TableText>Member</TableText>
                </TableItem>
                <TableItem centerContent>
                  <TableText>Projects</TableText>
                </TableItem>
                <TableItem centerContent>
                  <TableText>Teams</TableText>
                </TableItem>
                <TableItem>
                  <TableText>Actions</TableText>
                </TableItem>
              </InviteHeadSection>
              {invitedMemberList.map((member, index) => (
                <InviteTableContainer key={index}>
                  <TableItem>
                    <CommImageInviteComp
                      member={member}
                      index={index}
                      size="36px"
                      resendInvite={resendMemberInvitation}
                    />
                  </TableItem>
                  <TableItem centerContent>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                      }}
                      onMouseEnter={() => {
                        if (member.assignedProjects?.length > 0) {
                          setInvitedMemberIdForProject(member.id);
                        }
                      }}
                      onMouseLeave={() => setInvitedMemberIdForProject(null)}
                    >
                      <AssigneeMoreText fontSize="13px">
                        {member.assignedProjects?.length}
                      </AssigneeMoreText>
                      {invitedMemberIdForProject &&
                        invitedMemberIdForProject === member.id &&
                        member.assignedProjects?.length > 0 && (
                          <ProjectListPopup
                            projectList={member.assignedProjects}
                          />
                        )}
                    </div>
                  </TableItem>
                  {/* <TableItem>
                    <InviteProjectSection>
                      {member.assignedProjects &&
                        member.assignedProjects.length > 0 &&
                        member.assignedProjects.map((project, index) => (
                          <AssignedProjectBox key={index}>
                            {project.name.length < 12
                              ? project.name
                              : `${project.name.slice(0, 12)}...`}
                          </AssignedProjectBox>
                        ))}
                      {member.assignedProjects &&
                        member.assignedProjects.length === 0 && (
                          <TableText name>No projects</TableText>
                        )}
                    </InviteProjectSection>
                  </TableItem> */}
                  <TableItem centerContent>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                      }}
                      onMouseEnter={() => {
                        if (member.assignedTeams?.length > 0) {
                          setInvitedMemberIdForTeam(member.id);
                        }
                      }}
                      onMouseLeave={() => setInvitedMemberIdForTeam(null)}
                    >
                      <AssigneeMoreText fontSize="13px">
                        {member.assignedTeams?.length}
                      </AssigneeMoreText>
                      {invitedMemberIdForTeam &&
                        invitedMemberIdForTeam === member.id &&
                        member.assignedTeams?.length > 0 && (
                          <ProjectListPopup
                            projectList={member.assignedTeams}
                          />
                        )}
                    </div>
                  </TableItem>
                  {/* <TableItem>
                    <InviteProjectSection>
                      {member.assignedTeams &&
                        member.assignedTeams.length > 0 &&
                        member.assignedTeams.map((project, index) => (
                          <AssignedProjectBox key={index}>
                            {project.name.length < 12
                              ? project.name
                              : `${project.name.slice(0, 12)}...`}
                          </AssignedProjectBox>
                        ))}
                      {member.assignedTeams &&
                        member.assignedTeams.length === 0 && (
                          <TableText name>No Teams</TableText>
                        )}
                    </InviteProjectSection>
                  </TableItem> */}
                  <TableItem style={{ margin: `auto 0` }}>
                    <Select
                      isSearchable={false}
                      value={null}
                      options={inviteActionOptions}
                      placeholder="Actions"
                      onChange={(e) => {
                        onInviteActionChange(e, member);
                      }}
                      styles={createProjectDropdown(null)}
                    />
                  </TableItem>
                </InviteTableContainer>
              ))}
            </>
          )}

          {invitedMemberList &&
            invitedMemberList.length === 0 &&
            !props.isLoading && (
              <div
                style={{
                  padding: "40px 0px 20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!props.searchTerm ? (
                  <span>
                    You don't have invited members.{" "}
                    <InviteLink
                      onClick={() =>
                        props.history.push("/user/member-invitation")
                      }
                    >
                      Click here to invite one.
                    </InviteLink>
                  </span>
                ) : (
                  `No member found with this email.`
                )}
              </div>
            )}
        </>
      )}
    </Container>
  );
};

export default MembersTable;
