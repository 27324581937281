import { act, useState } from "react";
import { IconButton } from "../../styledComponents/common";
import {
  BillingTooltipText,
  BillingTooltip,
} from "../../styledComponents/createProject";
import styled from "styled-components";
import alert from "../../assets/img/icons/alert.svg";

const ToolTipText = styled(BillingTooltipText)``;
const ToolTipDiv = styled.div``;

function WarningTooltip(props) {
  const { text } = props;
  const [active, setActive] = useState(false);
  const isSmallTableHead = text.includes("Apps") || text.includes("URL")

  return (
    <ToolTipDiv
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <img src={alert} alt="Warning" />
      {active && (
        <div style={{ position: "relative" }}>
          <BillingTooltip
            top="-70px"
            left={props.left}
            width={isSmallTableHead ? '200px' : '250px'}
            padding={props.tooltipPadding}
            bgColor={props.bgColor}
          >
            <ToolTipText>{text}</ToolTipText>
          </BillingTooltip>
        </div>
      )}
    </ToolTipDiv>
  );
}

export default WarningTooltip;
