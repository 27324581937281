import { useState, useEffect } from "react";
import {
  OrgSettingsSection,
  SettingPropertySection,
  ToggleButton,
  ToggleButtonRound,
  WarningDiv,
  ScreenshotFrequencyButton,
  IdleTimeCollapseContent,
  IdleTimeDescription,
  IdleTimeInput,
} from "./activitySettingsStyles";

import {
  PageTitle,
  ContentHeader,
  CardTitle,
  CommonText,
  TableText,
  Container,
  CommonGrid,
  CommonImage,
  ColoredText,
  CommonFlex,
  FormAlert,
} from "../../../styledComponents/common";
import { ActivityTextSection } from "../../../styledComponents/members";
import {
  CheckboxDiv,
  CheckboxTickIcon,
} from "../../../styledComponents/settings";
import { PrimaryButton } from "../../../styledComponents/buttons";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import MembersTable from "./MembersTable";

import tickWhite from "../../../assets/img/icons/tick_2.svg";
import alertIcon from "../../../assets/img/icons/alert.svg";

const ScreenshotSection = (props) => {
  const {
    selectedOrganization,

    updateScreenshotSettings,
    screenshotsTracking,
    screenshotsBlur,
    screenshotsFrequency,
    screenshotsDelete,
    askInstantScreenshotPermission,
    showScreenshotNotification,
  } = props;

  const updateScreenshotTracking = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        screenshot_track: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  const updateScreenshotBlur = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        screenshot_blur: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  const updateScreenshotDelete = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        user_delete_screenshot: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  const updateAskPermissionForInstantScreenshot = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        ask_instant_screenshot_permission: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  const updateShowScreenshotNotification = (value) => {
    if (selectedOrganization?.configuration?.id) {
      const payload = {
        organization_id: selectedOrganization?.id,
        configuration_id: selectedOrganization.configuration.id,
        show_screenshot_notification: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  const screenshotsFrequencyChange = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id &&
      screenshotsFrequency != value
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        screenshot_frequency: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  return (
    <Container padding="25px" style={{ margin: "20px 0" }}>
      <CommonText name title margin="0 0 10px 0">
        Screenshot Tracking
      </CommonText>
      <SettingPropertySection>
        <CommonGrid columns="45% 55%" gap="2em">
          <CommonText $label fontWeight="500" margin="0">
            Control tracking screenshots for whole organization.
          </CommonText>
          <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
            <ToggleButton
              onClick={() => updateScreenshotTracking(!screenshotsTracking)}
              active={screenshotsTracking}
            >
              <ToggleButtonRound active={screenshotsTracking} />
            </ToggleButton>
            <CommonText
              $label={!screenshotsTracking}
              fontWeight="500"
              fontSize="14px"
              margin="0"
            >
              {screenshotsTracking ? "ON" : "OFF"}
            </CommonText>
          </CommonGrid>
        </CommonGrid>
      </SettingPropertySection>
      {
        screenshotsTracking && (
          <>
            <SettingPropertySection margin="30px 0 0 0">
              <CommonGrid columns="45% 55%" gap="2em">
                <CommonText name title margin="0 0 10px 0">
                  Screenshot Frequency
                </CommonText>
                <CommonGrid
                  columns="auto auto auto"
                  gap="1%"
                  justifyContent="start"
                >
                  <ScreenshotFrequencyButton
                    isActive={screenshotsFrequency === 1}
                    onClick={() => screenshotsFrequencyChange(1)}
                  >
                    1 Shot <span> /10 Mins</span>
                  </ScreenshotFrequencyButton>
                  <ScreenshotFrequencyButton
                    isActive={screenshotsFrequency === 2}
                    onClick={() => screenshotsFrequencyChange(2)}
                  >
                    2 Shots <span> /10 Mins</span>
                  </ScreenshotFrequencyButton>
                  <ScreenshotFrequencyButton
                    isActive={screenshotsFrequency === 3}
                    onClick={() => screenshotsFrequencyChange(3)}
                  >
                    3 Shots <span>/10 Mins</span>
                  </ScreenshotFrequencyButton>
                </CommonGrid>
              </CommonGrid>
              <CommonGrid columns="45% 55%" gap="2em" margin="10px 0 0 0">
                <CommonText $label fontWeight="500" margin="0">
                  Number of screenshots in every 10 minutes period.
                </CommonText>
                <CommonText $label fontWeight="500" margin="0">
                  {screenshotsFrequency && screenshotsFrequency * 6} screenshots
                  / hour
                </CommonText>
              </CommonGrid>
            </SettingPropertySection>
            <SettingPropertySection margin="30px 0 0 0">
              <CommonText name title margin="0 0 10px 0">
                Screenshot Blur
              </CommonText>
              <SettingPropertySection margin="10px 0 0 0">
                <CommonGrid columns="45% 55%" gap="2em">
                  <CommonText $label fontWeight="500" margin="0">
                    Control making screenshots blurred for security & privacy.
                  </CommonText>
                  <CommonGrid
                    columns="36px 30px"
                    gap="10px"
                    justifyContent="start"
                  >
                    <ToggleButton
                      onClick={() => updateScreenshotBlur(!screenshotsBlur)}
                      active={screenshotsBlur}
                    >
                      <ToggleButtonRound active={screenshotsBlur} />
                    </ToggleButton>
                    <CommonText
                      $label={!screenshotsBlur}
                      fontWeight="500"
                      fontSize="14px"
                      margin="0"
                    >
                      {screenshotsBlur ? "ON" : "OFF"}
                    </CommonText>
                  </CommonGrid>
                </CommonGrid>
              </SettingPropertySection>
            </SettingPropertySection>
            <SettingPropertySection margin="30px 0 0 0">
              <CommonText name title margin="0 0 10px 0">
                Delete Screenshot
              </CommonText>
              <SettingPropertySection margin="10px 0 0 0">
                <CommonGrid columns="45% 55%" gap="2em">
                  <CommonText $label fontWeight="500" margin="0">
                    Allow users to delete screenshots.
                  </CommonText>
                  <CommonGrid
                    columns="36px 30px"
                    gap="10px"
                    justifyContent="start"
                  >
                    <ToggleButton
                      onClick={() => updateScreenshotDelete(!screenshotsDelete)}
                      active={screenshotsDelete}
                    >
                      <ToggleButtonRound active={screenshotsDelete} />
                    </ToggleButton>
                    <CommonText
                      $label={!screenshotsDelete}
                      fontWeight="500"
                      fontSize="14px"
                      margin="0"
                    >
                      {screenshotsDelete ? "ON" : "OFF"}
                    </CommonText>
                  </CommonGrid>
                </CommonGrid>
              </SettingPropertySection>
            </SettingPropertySection>
            <SettingPropertySection margin="30px 0 0 0">
              <CommonText name title margin="0 0 10px 0">
                Request permission for instant screenshot
              </CommonText>
              <SettingPropertySection margin="10px 0 0 0">
                <CommonGrid columns="45% 55%" gap="2em">
                  <CommonText $label fontWeight="500" margin="0">
                    Allow users to accept/deny instant screenshot request.
                  </CommonText>
                  <CommonGrid
                    columns="36px 30px"
                    gap="10px"
                    justifyContent="start"
                  >
                    <ToggleButton
                      onClick={() =>
                        updateAskPermissionForInstantScreenshot(
                          !askInstantScreenshotPermission
                        )
                      }
                      active={askInstantScreenshotPermission}
                    >
                      <ToggleButtonRound
                        active={askInstantScreenshotPermission}
                      />
                    </ToggleButton>
                    <CommonText
                      $label={!askInstantScreenshotPermission}
                      fontWeight="500"
                      fontSize="14px"
                      margin="0"
                    >
                      {askInstantScreenshotPermission ? "ON" : "OFF"}
                    </CommonText>
                  </CommonGrid>
                </CommonGrid>
              </SettingPropertySection>
            </SettingPropertySection>
            <SettingPropertySection margin="30px 0 0 0">
              <CommonText name title margin="0 0 10px 0">
                Show screenshot notification
              </CommonText>
              <SettingPropertySection margin="10px 0 0 0">
                <CommonGrid columns="45% 55%" gap="2em">
                  <CommonText $label fontWeight="500" margin="0">
                    Allow users to see notification after the screenshot is
                    taken.
                  </CommonText>
                  <CommonGrid
                    columns="36px 30px"
                    gap="10px"
                    justifyContent="start"
                  >
                    <ToggleButton
                      onClick={() =>
                        updateShowScreenshotNotification(
                          !showScreenshotNotification
                        )
                      }
                      active={showScreenshotNotification}
                    >
                      <ToggleButtonRound active={showScreenshotNotification} />
                    </ToggleButton>
                    <CommonText
                      $label={!showScreenshotNotification}
                      fontWeight="500"
                      fontSize="14px"
                      margin="0"
                    >
                      {showScreenshotNotification ? "ON" : "OFF"}
                    </CommonText>
                  </CommonGrid>
                </CommonGrid>
              </SettingPropertySection>
            </SettingPropertySection>
          </>
        )
        // ) : (
        //   <WarningDiv>
        //     <CommonImage src={alertIcon} alt="" size="20px" />
        //     <ColoredText type="warning" fontSize="12px" margin="0 0 0 15px">
        //       You will not get screenshots of your members. However, Apps, URLs
        //       and Activity tracking is still enabled.
        //     </ColoredText>
        //   </WarningDiv>
        // )
      }
    </Container>
  );
};

const ActivitySection = (props) => {
  const {
    selectedOrganization,
    updateScreenshotSettings,
    urlTracking,
    appTracking,
    activityTracking,
  } = props;

  const updateURLTracking = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        url_track: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  const updateAppTracking = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        application_track: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  const updateActivityTracking = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        keystroke_track: value,
        mouse_track: value,
      };
      updateScreenshotSettings(payload);
    }
  };
  return (
    <Container padding="25px" style={{ margin: "20px 0" }}>
      <CommonText name title margin="0 0 10px 0">
        Activity
      </CommonText>
      <SettingPropertySection>
        <CommonGrid columns="45% 55%" gap="2em">
          <CommonText $label fontWeight="500" margin="0">
            Control tracking keyboard & mouse activity for whole organization.
          </CommonText>
          <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
            <ToggleButton
              onClick={() => updateActivityTracking(!activityTracking)}
              active={activityTracking}
            >
              <ToggleButtonRound active={activityTracking} />
            </ToggleButton>
            <CommonText
              $label={!activityTracking}
              fontWeight="500"
              fontSize="14px"
              margin="0"
            >
              {activityTracking ? "ON" : "OFF"}
            </CommonText>
          </CommonGrid>
        </CommonGrid>
      </SettingPropertySection>

      <CommonText name title margin="30px 0 10px 0">
        Apps
      </CommonText>
      <SettingPropertySection>
        <CommonGrid columns="45% 55%" gap="2em">
          <CommonText $label fontWeight="500" margin="0">
            Control tracking Apps for whole organization.
          </CommonText>
          <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
            <ToggleButton
              onClick={() => updateAppTracking(!appTracking)}
              active={appTracking}
            >
              <ToggleButtonRound active={appTracking} />
            </ToggleButton>
            <CommonText
              $label={!appTracking}
              fontWeight="500"
              fontSize="14px"
              margin="0"
            >
              {appTracking ? "ON" : "OFF"}
            </CommonText>
          </CommonGrid>
        </CommonGrid>
      </SettingPropertySection>
      <CommonText name title margin="30px 0 10px 0">
        URL
      </CommonText>
      <SettingPropertySection>
        <CommonGrid columns="45% 55%" gap="2em">
          <CommonText $label fontWeight="500" margin="0">
            Control tracking URLs for whole organization.
          </CommonText>
          <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
            <ToggleButton
              onClick={() => updateURLTracking(!urlTracking)}
              active={urlTracking}
            >
              <ToggleButtonRound active={urlTracking} />
            </ToggleButton>
            <CommonText
              $label={!urlTracking}
              fontWeight="500"
              fontSize="14px"
              margin="0"
            >
              {urlTracking ? "ON" : "OFF"}
            </CommonText>
          </CommonGrid>
        </CommonGrid>
      </SettingPropertySection>
    </Container>
  );
};

const OthersSection = (props) => {
  return (
    <Container padding="25px" style={{ margin: "20px 0" }}>
      <SettingPropertySection>
        <CommonText name title margin="0 0 10px 0">
          Activity Tracking
        </CommonText>
        <SettingPropertySection margin="10px 0 0 0">
          <CommonGrid columns="45% 55%" gap="2em">
            <CommonText $label fontWeight="500">
              Control mouse & keyboard activity tracking for whole organization
            </CommonText>
            <CommonGrid columns="36px 30px" gap="0.25em" justifyContent="start">
              <ToggleButton
                onClick={() => updateScreenshotBlur(!props.screenshotsBlur)}
                active={props.screenshotsBlur}
              >
                <ToggleButtonRound active={props.screenshotsBlur} />
              </ToggleButton>
              <CommonText
                $label={!props.screenshotsBlur}
                fontWeight="500"
                fontSize="14px"
                margin="0"
              >
                {props.screenshotsBlur ? "ON" : "OFF"}
              </CommonText>
            </CommonGrid>
          </CommonGrid>
        </SettingPropertySection>
      </SettingPropertySection>
      <SettingPropertySection margin="30px 0 0 0">
        <CommonText name title margin="0 0 10px 0">
          Apps Tracking
        </CommonText>
        <SettingPropertySection margin="10px 0 0 0">
          <CommonGrid columns="45% 55%" gap="2em">
            <CommonText $label fontWeight="500" margin="0">
              Control apps tracking for whole organization
            </CommonText>
            <CommonGrid columns="36px 30px" gap="0.25em" justifyContent="start">
              <ToggleButton
                onClick={() => updateScreenshotBlur(!props.screenshotsBlur)}
                active={props.screenshotsBlur}
              >
                <ToggleButtonRound active={props.screenshotsBlur} />
              </ToggleButton>
              <CommonText
                $label={!props.screenshotsBlur}
                fontWeight="500"
                fontSize="14px"
                margin="0"
              >
                {props.screenshotsBlur ? "ON" : "OFF"}
              </CommonText>
            </CommonGrid>
          </CommonGrid>
        </SettingPropertySection>
      </SettingPropertySection>
      <SettingPropertySection margin="30px 0 0 0">
        <CommonText name title margin="0 0 10px 0">
          URLs Tracking
        </CommonText>
        <SettingPropertySection margin="10px 0 0 0">
          <CommonGrid columns="45% 55%" gap="2em">
            <CommonText $label fontWeight="500" margin="0">
              Control URLs tracking for whole organization
            </CommonText>
            <CommonGrid columns="36px 30px" gap="0.25em" justifyContent="start">
              <ToggleButton
                onClick={() => updateScreenshotBlur(!props.screenshotsBlur)}
                active={props.screenshotsBlur}
              >
                <ToggleButtonRound active={props.screenshotsBlur} />
              </ToggleButton>
              <CommonText
                $label={!props.screenshotsBlur}
                fontWeight="500"
                fontSize="14px"
                margin="0"
              >
                {props.screenshotsBlur ? "ON" : "OFF"}
              </CommonText>
            </CommonGrid>
          </CommonGrid>
        </SettingPropertySection>
      </SettingPropertySection>
    </Container>
  );
};

const IdleTimeCollapseSection = (props) => {
  const [saveErrors, setSaveErrors] = useState({});

  const {
    activeOption,
    isActive,
    descriptionText,
    selectedOrganization,
    idleTimeSettings,
    updateIdleTimeSettings,
    durationInput,
    setDurationInput,
  } = props;

  useEffect(() => {
    if (idleTimeSettings && idleTimeSettings.id && idleTimeSettings.idle_time) {
      setDurationInput(Math.floor(idleTimeSettings.idle_time / 60));
    }
  }, [idleTimeSettings]);

  const handleDurationChange = (e) => {
    const { value, valueAsNumber } = e.target;
    if (!valueAsNumber) {
      setDurationInput(value);
    } else {
      setDurationInput(valueAsNumber);
    }

    if (Object.keys(saveErrors).length !== 0) {
      setSaveErrors({});
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!durationInput) {
      saveErrors["idle-time"] = "Duration can not be empty.";
      formIsValid = false;
    } else if (durationInput % 1 !== 0) {
      saveErrors["idle-time"] = "Fraction is not allowed.";
      formIsValid = false;
    } else if (durationInput < 5) {
      saveErrors["idle-time"] =
        "Idle time duration must be greater or equal to 5 minutes.";
      formIsValid = false;
    } else if (durationInput >= 1440) {
      saveErrors["idle-time"] =
        "Idle time duration must less than 1440 minutes.";
      formIsValid = false;
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSave = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        action:
          activeOption === "askMember"
            ? 1
            : activeOption === "dontAskMember"
            ? 2
            : 3,
      };
      if (activeOption === "never") {
        payload.idle_time = 0;
      } else {
        if (checkError()) {
          payload.idle_time = durationInput * 60;
        }
      }
      updateIdleTimeSettings(payload);
    }
  };

  return (
    <IdleTimeCollapseContent isActive={isActive}>
      <IdleTimeDescription>
        <ColoredText type="warning" fontSize="13px" fontWeight="600">
          {descriptionText}
        </ColoredText>
      </IdleTimeDescription>
      {activeOption && activeOption !== "never" ? (
        <ActivityTextSection margin="20px 0 0">
          <CommonText name title margin="0">
            Idle time detection period
          </CommonText>
          <CommonFlex gap="25px">
            <CommonFlex gap="8px">
              <CommonText $label fontWeight="500" margin="0">
                Select idle time detection period
              </CommonText>
              <IdleTimeInput
                type="number"
                min="5"
                value={durationInput}
                onChange={(e) => handleDurationChange(e)}
              />
              <CommonText $label fontWeight="500" margin="0">
                Mins
              </CommonText>
            </CommonFlex>
            <PrimaryButton
              disabled={durationInput < 5}
              height="36px"
              onClick={() => handleSave()}
            >
              Save Changes
            </PrimaryButton>
          </CommonFlex>
          {durationInput < 5 && (
            <FormAlert margin="0 180px">{"Minimum is 5 minutes."}</FormAlert>
          )}
        </ActivityTextSection>
      ) : (
        <CommonFlex margin="20px 0 0">
          <CommonText name title margin="0">
            No idle time will be detected
          </CommonText>
          <PrimaryButton height="36px" onClick={() => handleSave()}>
            Save Changes
          </PrimaryButton>
        </CommonFlex>
      )}
    </IdleTimeCollapseContent>
  );
};
const IdleTimeSection = (props) => {
  const [activeOption, setActiveOption] = useState(null);

  const [durationInput, setDurationInput] = useState(5);

  const { selectedOrganization, idleTimeSettings, updateIdleTimeSettings } =
    props;

  useEffect(() => {
    if (idleTimeSettings && idleTimeSettings.id) {
      switch (idleTimeSettings.action) {
        case 1:
          setActiveOption("askMember");
          break;
        case 2:
          setActiveOption("dontAskMember");
          break;
        case 3:
          setActiveOption("never");
          break;
        default:
      }
    }
  }, [idleTimeSettings]);

  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  return (
    <Container padding="25px" style={{ margin: "20px 0" }}>
      <CommonText name title>
        Idle Time
      </CommonText>
      <CommonFlex margin="20px 0 0">
        <CheckboxDiv
          active={activeOption === "askMember"}
          onClick={() => handleOptionChange("askMember")}
        >
          <CheckboxTickIcon src={tickWhite} alt="" />
        </CheckboxDiv>
        <CommonText>Ask Member</CommonText>
      </CommonFlex>
      <IdleTimeCollapseSection
        selectedOrganization={selectedOrganization}
        activeOption={activeOption}
        idleTimeSettings={idleTimeSettings}
        updateIdleTimeSettings={updateIdleTimeSettings}
        isActive={activeOption === "askMember"}
        durationInput={durationInput}
        setDurationInput={setDurationInput}
        descriptionText="Members will be notified whether they want to keep the detected time as idle or not"
      />
      <CommonFlex margin="20px 0 0">
        <CheckboxDiv
          active={activeOption === "dontAskMember"}
          onClick={() => handleOptionChange("dontAskMember")}
        >
          <CheckboxTickIcon src={tickWhite} alt="" />
        </CheckboxDiv>
        <CommonText>Don't Ask Member</CommonText>
      </CommonFlex>
      <IdleTimeCollapseSection
        selectedOrganization={selectedOrganization}
        activeOption={activeOption}
        idleTimeSettings={idleTimeSettings}
        updateIdleTimeSettings={updateIdleTimeSettings}
        isActive={activeOption === "dontAskMember"}
        durationInput={durationInput}
        setDurationInput={setDurationInput}
        descriptionText="Members will not be notified about idle time detection. Detected idle time will be marked as idle automatically."
      />
      <CommonFlex margin="20px 0 0">
        <CheckboxDiv
          active={activeOption === "never"}
          onClick={() => handleOptionChange("never")}
        >
          <CheckboxTickIcon src={tickWhite} alt="" />
        </CheckboxDiv>
        <CommonText>Never</CommonText>
      </CommonFlex>
      <IdleTimeCollapseSection
        selectedOrganization={selectedOrganization}
        activeOption={activeOption}
        idleTimeSettings={idleTimeSettings}
        updateIdleTimeSettings={updateIdleTimeSettings}
        isActive={activeOption === "never"}
        durationInput={durationInput}
        setDurationInput={setDurationInput}
        descriptionText={`On "Never" mode, idle time won't be detected for the members`}
      />
    </Container>
  );
};

const ActivitySettings = (props) => {
  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    isLoading,
    membersListLoading,
    history,
    selectedOrganization,

    getScreenshotSettings,
    updateScreenshotSettings,
    screenshotsTracking,
    screenshotsBlur,
    screenshotsFrequency,
    screenshotsDelete,

    getMembersList,
    updateMembersList,

    membersList,

    getIdleTimeSettings,
    updateIdleTimeSettings,
    idleTimeSettings,

    // pagination
    totalMembersCount,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };

      getIdleTimeSettings(payload);
    }
  }, []);

  useEffect(() => {
    // if (
    //   selectedOrganization &&
    //   selectedOrganization.id &&
    //   selectedOrganization.configuration &&
    //   selectedOrganization.configuration.id
    // ) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     configuration_id: selectedOrganization.configuration.id,
    //   };

    //   getScreenshotSettings(payload);
    // }
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      const payload = {
        organization_id: selectedOrganization.id,
      };

      if (membersList?.length == 0) {
        getMembersList(payload);
      }

      if (!idleTimeSettings) {
        getIdleTimeSettings(payload);
      }
    }
  }, [selectedOrganization]);

  const memberSettingsChange = (field, value) => {
    const toggleField = (key) => (key === field ? !value[key] : value[key]);

    if (
      selectedOrganization?.id &&
      selectedOrganization?.configuration?.id &&
      value != null
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        member_id: value.id,
        screenshot_track: toggleField("screenshot"),
        show_screenshot_notification: toggleField("screenshotNotification"),
        url_track: toggleField("url"),
        application_track: toggleField("app"),
        keystroke_track: toggleField("activity"),
        mouse_track: toggleField("activity"),
      };
      updateMembersList(payload);
    }
  };

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page,
      };

      getMembersList(payload);
    }
    setCurrentPageNumber(page);
  };

  return (
    <div className="content">
      <PageTitle>Activity Settings</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/settings")}
          subTitle="Back to settings"
        />
      </ContentHeader>

      <Container padding="0">
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {selectedOrganization &&
            (selectedOrganization.role === "owner" ||
              selectedOrganization.role === "admin") ? (
              <OrgSettingsSection>
                <CardTitle>Activity Settings</CardTitle>
                <ScreenshotSection
                  selectedOrganization={selectedOrganization}
                  updateScreenshotSettings={updateScreenshotSettings}
                  screenshotsTracking={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.screenshot_track
                  }
                  screenshotsBlur={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.screenshot_blur
                  }
                  screenshotsFrequency={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.screenshot_frequency
                  }
                  screenshotsDelete={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.user_delete_screenshot
                  }
                  askInstantScreenshotPermission={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration
                      .ask_instant_screenshot_permission
                  }
                  showScreenshotNotification={
                    selectedOrganization?.configuration
                      ?.show_screenshot_notification
                  }
                />
                <ActivitySection
                  selectedOrganization={selectedOrganization}
                  updateScreenshotSettings={updateScreenshotSettings}
                  urlTracking={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.url_track
                  }
                  appTracking={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.application_track
                  }
                  activityTracking={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.keystroke_track
                  }
                />
                {/* <OthersSection /> */}
                <IdleTimeSection
                  selectedOrganization={selectedOrganization}
                  idleTimeSettings={idleTimeSettings}
                  updateIdleTimeSettings={updateIdleTimeSettings}
                />
              </OrgSettingsSection>
            ) : null}

            <MembersTable
              selectedOrganization={selectedOrganization}
              membersListLoading={membersListLoading}
              membersList={membersList}
              history={history}
              memberSettingsChange={memberSettingsChange}
              onPageChange={handlePageChange}
              totalMembersCount={totalMembersCount}
              currentPageNumber={currentPageNumber}
              organizationSettings={selectedOrganization?.configuration}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default ActivitySettings;
