import { useState, useEffect } from "react";
import Select from "../../../components/ReactSelectDropdown";
import MembersDropdownStyle from "../../../components/DropdownStyle/MembersDropdownStyle";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import LinearActivityBar from "../../../components/ProgressBar/LinearActivityBar";
import queryString from "query-string";

import {
  CommImageNameComp,
  ApprovalSettingsSection,
} from "../CommonComponents";
import AddProjectDropdown from "../../../components/CustomDropdown/AddProjectDropdown";
import AddTeamDropdown from "../../../components/CustomDropdown/AddTeamDropdown";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import badgeGrayIcon from "../../../assets/img/icons/manager-badge-grey.svg";

import {
  PaddingBox,
  UpperTitleButtonSection,
  SingleSection,
  PersonSection,
  ActivityCard,
  ActivityTextSection,
  FlexSection,
  PesonActivitySection,
  TextSwitch,
  Dot,
  TextLink,
  ButtonFlexContainer,
  PaymentConfigTitle,
  Line,
  AddPayConfigTextSection,
  AddPayConfigMainSection,
  WeeklyLimitSection,
  TooltipContainerWorkOrder,
} from "../../../styledComponents/members";
import {
  PageTitle,
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  InputWithLabelSection,
  InputLabel,
  InputField,
  TextInputSection,
  ContentHeader,
  DividerLine,
  FormAlert,
  ManagerBadge,
  CommonImage,
} from "../../../styledComponents/common";
import {
  WhiteButtonWithIcon,
  ButtonIcon,
  ColoredButton,
  WhiteButton,
  PrimaryButton,
} from "../../../styledComponents/buttons";
import { HoveredSection, RoundHover } from "../../../styledComponents/clients";

import { toHHMMSS, getHours, getFullName } from "../../../utils/helper";

import { currencySymbolMap } from "../../../utils/currencies";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import MemberDeleteModal from "../MemberDeleteModal";

import Bean from "../../../assets/img/icons/delete.svg";
import CrossIcon from "../../../assets/img/icons/cross_black.svg";

import AssignButtonLoader from "components/AssignButtonLoader/AssignButtonLoader";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import CommonTooltip from "../../../components/Tooltip/CommonTooltip";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import badge from "../../../assets/img/icons/manager_badge.svg";
import alert from '../../../assets/img/icons/alert.svg';

const MemberDetails = (props) => {
  const [memberDetails, setMemberDetails] = useState(null);
  const [weeklyData, setWeeklyData] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [payPeriodValue, setPayPeriodValue] = useState({
    value: "1",
    label: "Weekly",
  });
  const [payTypeValue, setPayTypeValue] = useState({
    value: "1",
    label: "Hourly Payment",
  });
  const RoleOptions = [
    //{ value: "owner", label: "Owner" },
    { value: "admin", label: "Admin" },
    { value: "member", label: "Member" },
  ];
  const payPeriodOptions = [
    { value: "1", label: "Weekly" },
    { value: "2", label: "Monthly" },
  ];
  const payTypeOptions = [
    { value: "1", label: "Hourly Payment" },
    { value: "2", label: "Fixed Payment" },
  ];
  const [editWeeklyLimit, setEditWeeklyLimit] = useState(false);
  const [weeklyLimitValue, setWeeklyLimitValue] = useState("");
  const [payRateValue, setPayRateValue] = useState("");
  const [payRateChanged, setPayRateChanged] = useState(false);
  const [billRateValue, setBillRateValue] = useState("");
  const [billRateChanged, setBillRateChanged] = useState(false);
  const [tabSelect, setTabSelect] = useState("week");
  const colorList = [
    { color: "#9A96F9" },
    { color: "#73C0FF" },
    { color: "#F9828B" },
    { color: "#F9D059" },
  ];
  const [modal, setModal] = useState(false);
  const [projectIndex, setProjectIndex] = useState(-1);
  const [teamIndex, setTeamIndex] = useState(-1);
  const [tooltip, setTooltip] = useState(-1);
  const [showLimitSection, setShowLimitSection] = useState(false);
  const [saveErrors, setSaveErrors] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [managerTooltip, setManagerTooltip] = useState(false);

  const onHoverChange = (index) => {
    setSelectedIndex(index);
  };

  const {
    isLoading,
    history,
    location,
    selectedOrganization,
    clearMemberDetailsData,
    getMemberDetails,
    getMemberWeeklyEarning,
    getMemberMonthlyEarning,
    weeklyEarning,
    monthlyEarning,
    removeMember,
    updateMember,
    updateTeamMember,
    addTeam,
    assignProject,
    setPaymentConfiguration,
    deleteProjectAssignee,
    removeTeamMember,
    deleteMemberLoading,
    updateMemberLoading,
    teamMemberIsLoading,
    teamListIsLoading,
    getTeamShortList,
    teamList,
    projectAssigneeIsLoading,
    projectListIsLoading,
    getProjectShortList,
    projectList,
    paymentConfigurationLoading,
    updateMemberwiseTimesheetApprovalSettings,
    refetchProjects,
  } = props;

  useEffect(() => {
    return () => {
      clearMemberDetailsData();
    };
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        if (
          queryParsed?.id &&
          typeof queryParsed.id !== "undefined" &&
          queryParsed.id.trim() !== ""
        ) {
          let payload = {
            organization_id: selectedOrganization.id,
            user_id: queryParsed.id,
          };
          getMemberDetails(payload);
          getMemberWeeklyEarning(payload);
          getMemberMonthlyEarning(payload);
        }
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (props.memberDetails && props.memberDetails !== null) {
      const details = {
        id: props.memberDetails.id,
        userId: props.memberDetails.user.id,
        name: getFullName(props.memberDetails.user),
        email: props.memberDetails.user.email,
        timezone: props.memberDetails.user.timezone,
        avatar: props.memberDetails.user.avatar || null,
        color: props.memberDetails.user.color || null,
        role: {
          value: props.memberDetails.role,
          label: `${props.memberDetails.role
            .charAt(0)
            .toUpperCase()}${props.memberDetails.role.slice(1)}`,
        },
        teams: props.memberDetails.teams,
        assignedProjects: props.memberDetails.projects,
        payment: props.memberDetails.payment
          ? props.memberDetails.payment
          : null,
        requireTimesheetApproval:
          props.memberDetails.require_timesheet_approval,
        limitTracking: props.memberDetails.limit_tracking,
        weeklyLimit: props.memberDetails.weekly_tracking_limit,
      };
      setMemberDetails(details);
      if (props.memberDetails.limit_tracking) {
        setShowLimitSection(true);
      }
      if (props.memberDetails.payment) {
        if (props.memberDetails.payment.pay_rate) {
          setPayRateValue(
            (props.memberDetails.payment.pay_rate / 100).toFixed(2)
          );
        }
        if (props.memberDetails.payment.bill_rate) {
          setBillRateValue(
            (props.memberDetails.payment.bill_rate / 100).toFixed(2)
          );
        }
      }
    }
  }, [props.memberDetails]);

  useEffect(() => {
    if (weeklyEarning && weeklyEarning !== null) {
      setWeeklyData(weeklyEarning);
    }
  }, [weeklyEarning]);

  useEffect(() => {
    if (monthlyEarning && monthlyEarning !== null) {
      setMonthlyData(monthlyEarning);
    }
  }, [monthlyEarning]);

  useEffect(() => {
    if (editWeeklyLimit && memberDetails && memberDetails.weeklyLimit) {
      setWeeklyLimitValue(Math.floor(memberDetails.weeklyLimit / 3600));
    } else if (!editWeeklyLimit) {
      setWeeklyLimitValue("");
    }
  }, [editWeeklyLimit]);

  const toggleDeleteModal = () => {
    setModal(!modal);
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (weeklyLimitValue > 168) {
      formIsValid = false;
      saveErrors["exceedWeeklyLimit"] = "Maximum weekly limit is 168 hours.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleDeleteMember = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetails &&
      memberDetails.userId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        user_id: memberDetails.userId,
      };
      removeMember(payload);
      setTimeout(() => {
        toggleDeleteModal();
        history.push("/user/members");
      }, 300);
    }
  };

  const onRoleChange = (e) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetails &&
      memberDetails.userId &&
      memberDetails.role
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        user_id: memberDetails.userId,
      };
      if (
        (memberDetails.role.value === "admin" ||
          memberDetails.role.value === "project manager") &&
        e.value === "member"
      ) {
        payload.is_admin = false;
        updateMember(payload);
      } else if (
        (memberDetails.role.value === "member" ||
          memberDetails.role.value === "project manager") &&
        e.value === "admin"
      ) {
        payload.is_admin = true;
        updateMember(payload);
      }
    }
  };

  const updateManager = (orgId, teamId, TeamMemberShipId, canManage) => {
    if (orgId && teamId && TeamMemberShipId) {
      const payload = {
        organization_id: orgId,
        team_id: teamId,
        member_id: TeamMemberShipId,
        can_manage: canManage,
      };
      updateTeamMember(payload);
    }
  };

  const onTabSwitch = (value) => {
    setTabSelect(value);
  };

  const onWeeklyLimitChange = (e) => {
    const { value } = e.target;

    setWeeklyLimitValue(value);
  };

  const onPayPeriodChange = (e) => {
    setPayPeriodValue(e);
  };
  const onPayTypeChange = (e) => {
    setPayTypeValue(e);
  };

  const onPayRateChange = (e) => {
    const { value } = e.target;
    if(value){
      setPayRateValue(value);
    }else{
      setPayRateValue(0);
    }
    setPayRateChanged(true);
  };
  const onBillRateChange = (e) => {
    const { value } = e.target;
    if(value){
      setBillRateValue(value);
    }else{
      setBillRateValue(0);
    }
    setBillRateChanged(true);
  };

  const handleAddTeam = (team) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      team &&
      team.id &&
      memberDetails &&
      memberDetails.userId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: team.id,
        user_id: memberDetails.userId,
        getMemberData: true,
        isFromMemberDetailsPage: true,
      };
      addTeam(payload);
    }
  };

  const handleAssignProject = (project) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetails &&
      memberDetails.userId &&
      project &&
      project.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: project.id,
        user_id: memberDetails.userId,
        getMemberData: true,
      };
      assignProject(payload);
    }
  };

  const cancelFunction = () => {
    console.log("cancel submit");
  };

  const handleSavePayment = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetails &&
      memberDetails.id &&
      memberDetails.userId
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        user_id: memberDetails.userId,
        pay_rate: parseInt(payRateValue * 100),
        bill_rate: parseInt(billRateValue * 100),
        currency:
          (selectedOrganization.configuration &&
            selectedOrganization.configuration.currency) ||
          "USD",
      };

      setPaymentConfiguration(payload);
    }
    setPayRateChanged(false);
    setBillRateChanged(false);
  };

  const handleSaveWeeklyLimit = (limitTracking) => {
    if (checkError()) {
      if (
        selectedOrganization &&
        selectedOrganization.id &&
        memberDetails &&
        memberDetails.userId
      ) {
        const payload = {
          organization_id: selectedOrganization.id,
          user_id: memberDetails.userId,
        };
        if (limitTracking) {
          payload.limit_tracking = true;
          payload.weekly_tracking_limit = weeklyLimitValue * 3600;
        } else if (!limitTracking) {
          payload.limit_tracking = false;
          payload.weekly_tracking_limit = 0;
        }
        updateMember(payload);
        setTimeout(() => {
          setEditWeeklyLimit(false);
        }, 400);
      }
    }
  };

  const onProjectHover = (index) => {
    setProjectIndex(index);
  };

  const onTeamHover = (index) => {
    setTeamIndex(index);
  };

  const tooltipChange = (index) => {
    setTooltip(index);
  };

  const handleDeleteProject = (projectId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      projectId &&
      memberDetails &&
      memberDetails.userId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        user_id: memberDetails.userId,
        isFromMemberDetailsPage: true,
      };
      deleteProjectAssignee(payload);
    }
  };

  const handleDeleteTeam = (teamId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      teamId &&
      memberDetails &&
      memberDetails.userId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: teamId,
        user_id: memberDetails.userId,
        isFromMemberDetailsPage: true,
      };
      removeTeamMember(payload);
    }
  };

  const updateTimesheetApprovalSettings = (memberId, value) => {
    if (selectedOrganization && selectedOrganization.id && memberId) {
      const payload = {
        organization_id: selectedOrganization.id,
        settings_id: memberId,
        require_timesheet_approval: value,
      };
      updateMemberwiseTimesheetApprovalSettings(payload);
    }
  };

  return (
    <div className="content">
      <PageTitle>Members</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/members")}
          subTitle="Back to all members"
        />
      </ContentHeader>
      <Container style={{ padding: `30px 0` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : memberDetails && memberDetails !== null ? (
          <>
            <UpperTitleButtonSection>
              <CardTitle>Member Details</CardTitle>
              {selectedOrganization &&
                (selectedOrganization.role === "owner" ||
                  selectedOrganization.role === "admin") && (
                  <UpperTitleButtonSection gap="0" padding="0">
                    {memberDetails.role.value !== "owner" && (
                      <WhiteButtonWithIcon
                        type="delete"
                        fontSize="14px"
                        onClick={() => toggleDeleteModal()}
                      >
                        <ButtonIcon src={Bean} alt="" width="18px" />
                        Delete Member
                      </WhiteButtonWithIcon>
                    )}

                    <MemberDeleteModal
                      isOpen={modal}
                      toggle={toggleDeleteModal}
                      handleDelete={handleDeleteMember}
                      isLoading={deleteMemberLoading}
                    />
                  </UpperTitleButtonSection>
                )}
            </UpperTitleButtonSection>

            <SingleSection margin="0 0 20px 0">
              <PersonSection>
                <CommImageNameComp
                  imgSource={memberDetails.avatar}
                  name={memberDetails.name}
                  email={memberDetails.email}
                  size="70px"
                  fontSize="22px"
                  color={memberDetails.color}
                />

                <InputWithLabelSection style={{ justifyContent: "flex-end" }}>
                  {/* <InfoTooltip
                      mainText="Role"
                      toolTipText="Project Type Sed ut perspiciatis unde omnis iste natus error sit"
                    /> */}
                  <InputLabel>Role</InputLabel>
                  {selectedOrganization &&
                  (selectedOrganization.role === "owner" ||
                    selectedOrganization.role === "admin") &&
                  memberDetails.role.value !== "owner" ? (
                    <>
                      {updateMemberLoading ? (
                        <AssignButtonLoader
                          padding="9px 10px"
                          borderColor="rgb(228, 231, 235)"
                          width="150px"
                        />
                      ) : (
                        <Select
                          isSearchable
                          value={memberDetails.role}
                          options={RoleOptions}
                          onChange={(e) => {
                            onRoleChange(e);
                          }}
                          styles={MembersDropdownStyle({
                            minHeight: "40px",
                            minWidth: "150px",
                          })}
                        />
                      )}
                    </>
                  ) : (
                    <CommonText>{memberDetails.role.label}</CommonText>
                  )}
                </InputWithLabelSection>
              </PersonSection>
              {memberDetails.timezone && (
                <CommonFlex gap="5px" margin="10px 0 20px">
                  <CommonText label fontWeight="500" fontSize="13px">
                    Timezone:
                  </CommonText>
                  <CommonText fontSize="13px">
                    UTC ({memberDetails.timezone.offset}){" "}
                    {memberDetails.timezone.tz}
                  </CommonText>
                </CommonFlex>
              )}
              <ActivityCard>
                <FlexSection>
                  <TextSwitch
                    onClick={() => onTabSwitch("week")}
                    tabSelect={tabSelect}
                    index={"week"}
                  >
                    This Week
                  </TextSwitch>
                  <TextSwitch
                    onClick={() => onTabSwitch("month")}
                    tabSelect={tabSelect}
                    index={"month"}
                  >
                    Month
                  </TextSwitch>
                </FlexSection>
                <PesonActivitySection
                  earned={
                    tabSelect === "week"
                      ? weeklyData && weeklyData.total_earned > 0
                      : monthlyData && monthlyData.total_earned > 0
                  }
                >
                  <ActivityTextSection>
                    <CommonText fontSize="16px" title>
                      {tabSelect === "week"
                        ? weeklyData &&
                          getHours(toHHMMSS(weeklyData.total_time))
                        : monthlyData &&
                          getHours(toHHMMSS(monthlyData.total_time))}
                    </CommonText>
                    <CommonText $label fontWeight="500">
                      Time Worked
                    </CommonText>
                  </ActivityTextSection>
                  <ActivityTextSection>
                    <FlexSection>
                      <LinearActivityBar
                        percent={
                          tabSelect === "week"
                            ? weeklyData &&
                              (weeklyData.avg_activity * 100).toFixed(2)
                            : monthlyData &&
                              (monthlyData.avg_activity * 100).toFixed(2)
                        }
                      />
                      <CommonText fontSize="16px" title>
                        {tabSelect === "week"
                          ? `${
                              weeklyData
                                ? (weeklyData.avg_activity * 100).toFixed(2)
                                : "0"
                            }%`
                          : `${
                              monthlyData
                                ? (monthlyData.avg_activity * 100).toFixed(2)
                                : "0"
                            }%`}
                      </CommonText>
                    </FlexSection>
                    <CommonText $label fontWeight="500">
                      Activity
                    </CommonText>
                  </ActivityTextSection>
                  <ActivityTextSection>
                    <CommonText fontSize="16px" title>
                      {tabSelect === "week"
                        ? memberDetails.payment &&
                          memberDetails.payment.pay_rate &&
                          weeklyData
                          ? `${
                              currencySymbolMap[memberDetails.payment.currency]
                            } ${weeklyData.total_earned}`
                          : `N/A`
                        : memberDetails.payment &&
                          memberDetails.payment.pay_rate &&
                          monthlyData
                        ? `${
                            currencySymbolMap[memberDetails.payment.currency]
                          } ${monthlyData.total_earned}`
                        : `N/A`}
                    </CommonText>
                    <CommonText $label fontWeight="500">
                      Earned
                    </CommonText>
                  </ActivityTextSection>
                  {/* {tabSelect === "week" ? (
                      weeklyData && weeklyData.total_earned > 0 ? (
                        <CommonColorButton
                          padding="13px 25px"
                          style={{ margin: `auto 0` }}
                        >
                          View Payment Info
                        </CommonColorButton>
                      ) : null
                    ) : monthlyData && monthlyData.total_earned > 0 ? (
                      <CommonColorButton
                        padding="13px 25px"
                        style={{ margin: `auto 0` }}
                      >
                        View Payment Info
                      </CommonColorButton>
                    ) : null} */}
                </PesonActivitySection>
                {/* <FlexSection>
                    <TextLink>View Timesheet</TextLink>
                    <Dot />
                    <TextLink>View Activity</TextLink>
                  </FlexSection> */}
              </ActivityCard>
            </SingleSection>
            <SingleSection>
              <CommonText $label>Working For These Teams:</CommonText>
              <ButtonFlexContainer>
                {memberDetails.teams.map((team, index) => (
                  <ColoredButton
                    bgColor={
                      team.color
                        ? team.color
                        : colorList[index > 3 ? index % 4 : index].color
                    }
                    key={index}
                    onMouseEnter={() => onTeamHover(index)}
                    onMouseLeave={() => onTeamHover(-1)}
                    style={{ position: "relative" }}
                  >
                    <span
                      onMouseEnter={() => onHoverChange(index)}
                      onMouseLeave={() => onHoverChange(-1)}
                    >
                      {team.is_manager && (
                        <ManagerBadge
                          src={badge}
                          alt="manager-badge"
                          width="24px"
                          style={{
                            position: "relative",
                            paddingBottom: "10px",
                          }}
                          right="0px"
                          // left="-18px"
                          left="-10px"
                          onMouseEnter={() => setManagerTooltip("manager")}
                          onMouseLeave={() => setManagerTooltip(false)}
                          onClick={() =>
                            updateManager(
                              team.organization,
                              team.id,
                              team.team_membership_id,
                              false
                            )
                          }
                        />
                      )}

                      {managerTooltip &&
                        managerTooltip === "manager" &&
                        selectedIndex === index && (
                          <CommonTooltip
                            tooltipText="Remove Manager"
                            left="-54px"
                            top="-35px"
                            width="130px"
                            height="25px"
                            padding="7px 10px"
                          />
                        )}

                      {teamIndex === index && !team.is_manager && (
                        <div>
                          <ManagerBadge
                            src={badgeGrayIcon}
                            alt="manager-badge"
                            width="24px"
                            style={{
                              position: "relative",
                              paddingBottom: "10px",
                            }}
                            right="0px"
                            // left="-18px"
                            left="-10px"
                            onMouseEnter={() => setManagerTooltip(true)}
                            onMouseLeave={() => setManagerTooltip(false)}
                            onClick={() =>
                              updateManager(
                                team.organization,
                                team.id,
                                team.team_membership_id,
                                true
                              )
                            }
                          />
                          {managerTooltip && (
                            <CommonTooltip
                              tooltipText="Make Manager"
                              left="-45px"
                              top="-35px"
                              width="115px"
                              height="25px"
                              padding="7px 10px"
                            />
                          )}
                        </div>
                      )}
                    </span>
                    <span
                      onClick={() => {
                        if (
                          selectedOrganization &&
                          (selectedOrganization.role === "owner" ||
                            selectedOrganization.role === "admin")
                        ) {
                          history.push(`/user/team-details?id=${team.id}`);
                        }
                      }}
                      style={{
                        maxWidth: "200px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        // marginLeft: "-14px",
                      }}
                      title={team.name}
                    >
                      {team.name}
                    </span>

                    {teamIndex === index &&
                      selectedOrganization &&
                      (selectedOrganization.role === "owner" ||
                        selectedOrganization.role === "admin") && (
                        <HoveredSection
                          style={{
                            gridTemplateColumns: "1fr",
                            right: "5px",
                          }}
                        >
                          <RoundHover
                            onMouseEnter={() => tooltipChange(1)}
                            onMouseLeave={() => tooltipChange(-1)}
                            onClick={() => handleDeleteTeam(team.id)}
                          >
                            <img src={CrossIcon} alt="cross" />
                            {tooltip === 1 && (
                              <CommonTooltip
                                tooltipText="Remove Team"
                                left="-58px"
                                top="-40px"
                                width="140px"
                              />
                            )}
                          </RoundHover>
                        </HoveredSection>
                      )}
                  </ColoredButton>
                ))}
                {teamMemberIsLoading ? (
                  <AssignButtonLoader />
                ) : selectedOrganization &&
                  (selectedOrganization.role === "owner" ||
                    selectedOrganization.role === "admin") ? (
                  <AddTeamDropdown
                    isLoading={teamListIsLoading}
                    selectedOrganization={selectedOrganization}
                    assignedTeams={memberDetails.teams}
                    getTeamList={getTeamShortList}
                    updateState={handleAddTeam}
                    teamsList={teamList}
                    buttonPadding="10px 15px"
                  />
                ) : memberDetails.teams && memberDetails.teams.length === 0 ? (
                  <CommonText>No Teams</CommonText>
                ) : null}
              </ButtonFlexContainer>
            </SingleSection>
            <SingleSection>
              <CommonText $label>Assigned Projects</CommonText>
              <ButtonFlexContainer>
                {memberDetails.assignedProjects.map((project, index) => (
                  <WhiteButton
                    type="cancel"
                    key={index}
                    onMouseEnter={() => onProjectHover(index)}
                    onMouseLeave={() => onProjectHover(-1)}
                    style={{ position: "relative" }}
                  >
                    <span
                      onClick={() => {
                        if (
                          selectedOrganization &&
                          selectedOrganization.role !== "member"
                        ) {
                          history.push(
                            `/user/project-details?id=${project.id}&type=${
                              project.is_active ? "active" : "archived"
                            }`
                          );
                        }
                      }}
                    >
                      {project.name}
                    </span>
                    {projectIndex === index &&
                      selectedOrganization &&
                      selectedOrganization.role !== "member" && (
                        <HoveredSection
                          style={{
                            gridTemplateColumns: "1fr",
                            right: "5px",
                          }}
                        >
                          <RoundHover
                            onMouseEnter={() => tooltipChange(1)}
                            onMouseLeave={() => tooltipChange(-1)}
                            onClick={() => handleDeleteProject(project.id)}
                          >
                            <img src={CrossIcon} alt="cross" />
                            {tooltip === 1 && (
                              <CommonTooltip
                                tooltipText="Unassign Project"
                                left="-58px"
                                top="-40px"
                                width="140px"
                              />
                            )}
                          </RoundHover>
                        </HoveredSection>
                      )}
                  </WhiteButton>
                ))}
                {projectAssigneeIsLoading ? (
                  <AssignButtonLoader height="40px" width="160px" />
                ) : selectedOrganization &&
                  selectedOrganization.role !== "member" ? (
                  <AddProjectDropdown
                    isLoading={projectListIsLoading}
                    selectedOrganization={selectedOrganization}
                    assignedProjects={memberDetails.assignedProjects}
                    getProjectList={getProjectShortList}
                    updateState={handleAssignProject}
                    projectsList={projectList}
                    refetchProjects={refetchProjects}
                    buttonPadding="9px 15px"
                  />
                ) : memberDetails.assignedProjects &&
                  memberDetails.assignedProjects.length === 0 ? (
                  <CommonText>No Projects</CommonText>
                ) : null}
              </ButtonFlexContainer>
            </SingleSection>

            <SingleSection margin="10px 0 20px">
              <ApprovalSettingsSection
                margin="0"
                hasTooltip
                memberId={memberDetails.id}
                active={memberDetails.requireTimesheetApproval}
                handleChange={updateTimesheetApprovalSettings}
                isAdmin={
                  selectedOrganization && selectedOrganization.role !== "member"
                }
              />
            </SingleSection>

            {selectedOrganization?.role !== "team_leader" ? (
              <SingleSection>
                <ActivityTextSection>
                  <CommonText $label>Payment</CommonText>
                  {memberDetails && memberDetails.payment ? (
                    <CommonText>
                      {memberDetails.payment.currency} (
                      {currencySymbolMap[memberDetails.payment.currency]}){" "}
                      {(memberDetails.payment.pay_rate / 100).toFixed(2)} / hour
                    </CommonText>
                  ) : (
                    <CommonText>Payment not set</CommonText>
                  )}
                </ActivityTextSection>
              </SingleSection>
            ) : null}

            {selectedOrganization &&
              (selectedOrganization.role == "owner" ||
                selectedOrganization.role == "admin") && (
                <SingleSection>
                  <AddPayConfigMainSection>
                    {/* <TextInputSection>
                        <InfoTooltip
                          mainText="Pay Period"
                          toolTipText="Project Type Sed ut perspiciatis unde omnis iste natus error sit"
                        />
                        <Select
                          isSearchable
                          value={payPeriodValue}
                          options={payPeriodOptions}
                          onChange={(e) => {
                            onPayPeriodChange(e);
                          }}
                          styles={MembersDropdownStyle()}
                        />
                      </TextInputSection>
                      <TextInputSection>
                        <InfoTooltip
                          mainText="Payment Type"
                          toolTipText="Project Type Sed ut perspiciatis unde omnis iste natus error sit"
                        />
                        <Select
                          isSearchable
                          value={payTypeValue}
                          options={payTypeOptions}
                          onChange={(e) => {
                            onPayTypeChange(e);
                          }}
                          styles={MembersDropdownStyle()}
                        />
                      </TextInputSection> */}
                    <TextInputSection>
                      <InfoTooltip
                        mainText="Pay Rate"
                        toolTipText="Pay rate is used to manage your employee's payroll"
                        title
                        label
                      />
                      <CommonGrid columns="60px auto" gap="10px">
                        <CommonText>
                          {selectedOrganization &&
                            selectedOrganization.configuration &&
                            selectedOrganization.configuration.currency &&
                            `${selectedOrganization.configuration.currency} (${
                              currencySymbolMap[
                                selectedOrganization.configuration.currency
                              ]
                            })`}
                        </CommonText>
                        <InputField
                          height="36px"
                          style={{ width: "108px" }}
                          type="number"
                          placeholder="0.00"
                          value={payRateValue}
                          onChange={(e) => onPayRateChange(e)}
                        />
                      </CommonGrid>
                    </TextInputSection>

                    <CommonFlex gap="10px">
                      <TextInputSection>
                        <InfoTooltip
                          mainText="Bill Rate"
                          toolTipText="Bill rate is used to invoice your client"
                          top="-40px"
                          title
                          label
                        />
                        <CommonGrid columns="60px auto" gap="10px">
                          <CommonText>
                            {selectedOrganization &&
                              selectedOrganization.configuration &&
                              selectedOrganization.configuration.currency &&
                              `${selectedOrganization.configuration.currency} (${
                                currencySymbolMap[
                                  selectedOrganization.configuration.currency
                                ]
                              })`}
                          </CommonText>
                          <InputField
                            height="36px"
                            style={{ width: "108px" }}
                            type="number"
                            placeholder="0.00"
                            value={billRateValue}
                            onChange={(e) => onBillRateChange(e)}
                          />
                        </CommonGrid>
                      </TextInputSection>

                      {(payRateChanged || billRateChanged) &&
                        parseInt(billRateValue) <= parseInt(payRateValue) && 
                        !(parseInt(billRateValue)===0 && parseInt(payRateValue)===0) && (
                      <div
                        onMouseEnter={() => tooltipChange(true)}
                        onMouseLeave={() => tooltipChange(false)}
                      >
                        <CommonImage
                          style={{ marginTop: '28px' }}
                          size="22px"
                          src={alert}
                        />
                        {tooltip && (
                          <TooltipContainerWorkOrder
                            style={{
                              marginTop: '60px',
                              marginLeft: '-154px',
                            }}
                          >
                            <CommonText
                              fontSize="12px"
                              fontWeight="530"
                              color="#fff"
                            >
                              Check whether pay rate and bill rate are ok.
                            </CommonText>
                          </TooltipContainerWorkOrder>
                        )}
                      </div>
                      )}
                    </CommonFlex>

                    <PrimaryButton
                      height="36px"
                      style={{ alignSelf: "flex-end" }}
                      padding={
                        paymentConfigurationLoading ? "5px 15px" : "5px 30px"
                      }
                      onClick={() => handleSavePayment()}
                      disabled={!(payRateChanged || billRateChanged)}
                    >
                      {paymentConfigurationLoading ? (
                        <ButtonTextLoader
                          loadingText="Saving"
                          fontSize="13px"
                        />
                      ) : (
                        "Save"
                      )}
                    </PrimaryButton>
                  </AddPayConfigMainSection>
                  {/* <ButtonSection>
                    <Button save={false} onClick={() => cancelFunction()}>
                      Cancel
                    </Button>
                    <Button save onClick={() => handleSave()}>
                      Save
                    </Button>
                  </ButtonSection> */}
                </SingleSection>
              )}

            <DividerLine margin="25px 0 0" />

            <SingleSection margin="25px 0 0">
              <ActivityTextSection gap="12px">
                <CommonFlex gap="15px">
                  <CommonText $label>Weekly Limit</CommonText>
                  {selectedOrganization &&
                    selectedOrganization.role !== "member" && (
                      <ToggleButton
                        active={showLimitSection}
                        handleClick={() => {
                          if (memberDetails.limitTracking && showLimitSection) {
                            handleSaveWeeklyLimit(false);
                          }
                          setShowLimitSection(!showLimitSection);
                        }}
                      />
                    )}
                </CommonFlex>

                {showLimitSection &&
                selectedOrganization &&
                selectedOrganization.role !== "member" ? (
                  <ActivityTextSection gap="15px">
                    <CommonFlex gap="40px">
                      {editWeeklyLimit ? (
                        <CommonFlex gap="13px">
                          <InputField
                            style={{ width: `70px` }}
                            height="36px"
                            type="number"
                            placeholder="0"
                            min={0}
                            value={weeklyLimitValue}
                            onChange={(e) => onWeeklyLimitChange(e)}
                          />
                          <CommonText>Hours/ Week</CommonText>
                        </CommonFlex>
                      ) : (
                        <CommonText>
                          {memberDetails.weeklyLimit
                            ? `${Math.floor(
                                memberDetails.weeklyLimit / 3600
                              )} Hours/ Week`
                            : `No weekly limit is set`}
                        </CommonText>
                      )}

                      {editWeeklyLimit ? (
                        <CommonFlex gap="12px">
                          <WhiteButton
                            height="36px"
                            padding="0 15px"
                            onClick={() => setEditWeeklyLimit(false)}
                          >
                            Cancel
                          </WhiteButton>
                          <PrimaryButton
                            disabled={
                              !weeklyLimitValue || weeklyLimitValue <= 0
                            }
                            height="36px"
                            padding="0 15px"
                            onClick={() => handleSaveWeeklyLimit(true)}
                          >
                            Set Limit
                          </PrimaryButton>
                        </CommonFlex>
                      ) : (
                        <WhiteButton
                          height="36px"
                          padding="0 15px"
                          onClick={() => setEditWeeklyLimit(true)}
                        >
                          Edit Limit
                        </WhiteButton>
                      )}
                    </CommonFlex>
                    {memberDetails &&
                      memberDetails.payment &&
                      memberDetails.payment.pay_rate &&
                      memberDetails.limitTracking && (
                        <CommonText fontSize="12px" $label fontWeight="500">
                          {currencySymbolMap[memberDetails.payment.currency]}{" "}
                          {(
                            (memberDetails.payment.pay_rate *
                              (weeklyLimitValue
                                ? weeklyLimitValue
                                : memberDetails.weeklyLimit
                                ? Math.floor(memberDetails.weeklyLimit / 3600)
                                : 0)) /
                            100
                          ).toFixed(2)}{" "}
                          / Week
                        </CommonText>
                      )}
                    {saveErrors &&
                      saveErrors["exceedWeeklyLimit"] &&
                      saveErrors["exceedWeeklyLimit"] !== undefined && (
                        <FormAlert margin="0 0 0 5px">
                          {saveErrors["exceedWeeklyLimit"]}
                        </FormAlert>
                      )}
                  </ActivityTextSection>
                ) : (
                  <CommonText>
                    {memberDetails.weeklyLimit
                      ? `${Math.floor(
                          memberDetails.weeklyLimit / 3600
                        )} Hours/ Week`
                      : `No weekly limit is set`}
                  </CommonText>
                )}
              </ActivityTextSection>
            </SingleSection>
          </>
        ) : (
          <NoDataComponent title="Sorry! could not fetch member details. Please try again!" />
        )}
      </Container>
    </div>
  );
};

export default MemberDetails;
