//Effects
import { all, call, put, fork, takeLatest } from "redux-saga/effects";
//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  //Get Project
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_FAILURE,
  GET_PROJECT_SHORT_LIST,
  GET_PROJECT_SHORT_LIST_SUCCESS,
  GET_PROJECT_SHORT_LIST_FAILURE,
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  GET_PROJECT_BUDGET_AND_BILLABLE,
  GET_PROJECT_BUDGET_AND_BILLABLE_SUCCESS,
  GET_PROJECT_BUDGET_AND_BILLABLE_FAILURE,
  GET_PROJECT_EXPENSES,
  GET_PROJECT_EXPENSES_SUCCESS,
  GET_PROJECT_EXPENSES_FAILURE,
  GET_PROJECT_YEARLY_EXPENSES,
  GET_PROJECT_YEARLY_EXPENSES_SUCCESS,
  GET_PROJECT_YEARLY_EXPENSES_FAILURE,
  //Create
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_REQUEST_SUCCESS,
  CREATE_PROJECT_REQUEST_FAILURE,
  DUPLICATE_PROJECT_REQUEST,
  DUPLICATE_PROJECT_REQUEST_SUCCESS,
  DUPLICATE_PROJECT_REQUEST_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST_SUCCESS,
  UPDATE_PROJECT_REQUEST_FAILURE,
  GET_ORGANIZATION_MEMBERS_LIST,
  GET_ORGANIZATION_MEMBERS_LIST_SUCCESS,
  GET_ORGANIZATION_MEMBERS_LIST_FAILURE,
  ADD_TEAM_TO_PROJECT,
  ADD_TEAM_TO_PROJECT_SUCCESS,
  ADD_TEAM_TO_PROJECT_FAILURE,
  ADD_PROJECT_ASSIGNEE,
  ADD_PROJECT_ASSIGNEE_SUCCESS,
  ADD_PROJECT_ASSIGNEE_FAILURE,
  UPDATE_PROJECT_ASSIGNEE,
  UPDATE_PROJECT_ASSIGNEE_SUCCESS,
  UPDATE_PROJECT_ASSIGNEE_FAILURE,
  DELETE_PROJECT_ASSIGNEE,
  DELETE_PROJECT_ASSIGNEE_SUCCESS,
  DELETE_PROJECT_ASSIGNEE_FAILURE,
  GET_PROJECT_ASSIGNEE_LIST,
  GET_PROJECT_ASSIGNEE_LIST_SUCCESS,
  GET_PROJECT_ASSIGNEE_LIST_FAILURE,
  GET_PROJECT_TASK_LIST,
  GET_PROJECT_TASK_LIST_SUCCESS,
  GET_PROJECT_TASK_LIST_FAILURE,
  PROJECT_ADD_TASK,
  PROJECT_ADD_TASK_SUCCESS,
  PROJECT_ADD_TASK_FAILURE,
  PROJECT_EDIT_TASK,
  PROJECT_EDIT_TASK_SUCCESS,
  PROJECT_EDIT_TASK_FAILURE,
  PROJECT_DELETE_TASK,
  PROJECT_DELETE_TASK_SUCCESS,
  PROJECT_DELETE_TASK_FAILURE,
  GET_TASK_ASSIGNEE_LIST,
  GET_TASK_ASSIGNEE_LIST_SUCCESS,
  GET_TASK_ASSIGNEE_LIST_FAILURE,
  ADD_TASK_ASSIGNEE,
  ADD_TASK_ASSIGNEE_SUCCESS,
  ADD_TASK_ASSIGNEE_FAILURE,
  DELETE_TASK_ASSIGNEE,
  DELETE_TASK_ASSIGNEE_SUCCESS,
  DELETE_TASK_ASSIGNEE_FAILURE,
  //Delete Project
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST_SUCCESS,
  DELETE_PROJECT_REQUEST_FAILURE,
  //Budget and Billing
  GET_PROJECT_BUDGET,
  GET_PROJECT_BUDGET_SUCCESS,
  GET_PROJECT_BUDGET_FAILURE,
  ADD_PROJECT_BUDGET,
  ADD_PROJECT_BUDGET_SUCCESS,
  ADD_PROJECT_BUDGET_FAILURE,
  UPDATE_PROJECT_BUDGET,
  UPDATE_PROJECT_BUDGET_SUCCESS,
  UPDATE_PROJECT_BUDGET_FAILURE,
  GET_PROJECT_BILLING,
  GET_PROJECT_BILLING_SUCCESS,
  GET_PROJECT_BILLING_FAILURE,
  ADD_PROJECT_BILLING,
  ADD_PROJECT_BILLING_SUCCESS,
  ADD_PROJECT_BILLING_FAILURE,
  UPDATE_PROJECT_BILLING,
  UPDATE_PROJECT_BILLING_SUCCESS,
  UPDATE_PROJECT_BILLING_FAILURE,
  // invoices
  GET_PROJECT_INVOICES,
  GET_PROJECT_INVOICES_SUCCESS,
  GET_PROJECT_INVOICES_FAILURE,
  REMOVE_CLIENT_FROM_PROJECT,
  REMOVE_CLIENT_FROM_PROJECT_SUCCESS,
  REMOVE_CLIENT_FROM_PROJECT_FAILURE,
  REMOVE_TEAM_FROM_PROJECT_SUCCESS,
  REMOVE_TEAM_FROM_PROJECT_FAILURE,
  REMOVE_TEAM_FROM_PROJECT,
} from "../../modules/constants";

import {
  getTeamDetailsRequest,
  getTeamProjectsListRequest,
} from "../Teams/teamsSagas";
import { getMemberDetails } from "../Members/membersSagas";
import { getTaskList } from "../Tasks/tasksSagas";
import { getClientDetails } from "../Clients/clientsSagas";

export function* getProjectListRequest({ payload }) {
  try {
    const { organization_id, type, page, name, client_id } = payload;
    if (!organization_id && payload.id) {
      organization_id = payload.id;
    }
    const url = `${organization_id}/projects/?active=${
      type === "active" ? "true" : "false"
    }${page ? `&page=${page}` : ``}${name ? `&name=${name}` : ``}${
      client_id ? `&client=${client_id}` : ``
    }`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getProjectShortListRequest({ payload }) {
  try {
    const { organization_id, user_id, unassigned } = payload;
 
    if (!organization_id && payload.id) {
      organization_id = payload.id;
    }
    const url = `${organization_id}/projects/${
      unassigned ? "unassigned" : "short"
    }/?active=true${user_id ? `&user=${user_id}` : ``}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_SHORT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_SHORT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getProjectDetailsRequest({ payload }) {
  try {
    const { organization_id, project_id } = payload;
    const url = `${organization_id}/projects/${project_id}/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_DETAILS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* getProjectBudgetAndBillableRequest({ payload }) {
  try {
    const { organization_id, project_id } = payload;
    const url = `${organization_id}/projects/${project_id}/budget-expense/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_BUDGET_AND_BILLABLE_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_BUDGET_AND_BILLABLE_FAILURE,
      payload: err,
    });
  }
}

export function* getProjectExpensesRequest({ payload }) {
  try {
    const { organization_id, project_id, start_date, end_date } = payload;
    const url = `${organization_id}/projects/${project_id}/timesheets/expense-detailed-date?start=${start_date}&end=${end_date}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_EXPENSES_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_EXPENSES_FAILURE,
      payload: err,
    });
  }
}

export function* getProjectYearlyExpensesRequest({ payload }) {
  try {
    const { organization_id, project_id, start_date, end_date } = payload;
    const url = `${organization_id}/projects/${project_id}/timesheets/expense-detailed-month?start=${start_date}&end=${end_date}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_YEARLY_EXPENSES_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_YEARLY_EXPENSES_FAILURE,
      payload: err,
    });
  }
}

export function* createProjectRequest({ payload }) {
  try {
    const { organization_id, ...details } = payload;
    const url = `${organization_id}/projects/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    //yield call(getProjectList, { payload });
    yield put({
      type: CREATE_PROJECT_REQUEST_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: CREATE_PROJECT_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* duplicateProjectRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, project_id } = payload;
    const url = `${organization_id}/projects/${project_id}/duplicate/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: DUPLICATE_PROJECT_REQUEST_SUCCESS,
      payload: { data: response, project_id },
    });
  } catch (err) {
    yield put({
      type: DUPLICATE_PROJECT_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* updateProjectRequest({ payload }) {
  try {
    const {
      organization_id,
      project_id,
      mode,
      editGeneralDetails,
      getAllProjectList,
      clientDetails,
      type,
      ...details
    } = payload;
    const url = `${organization_id}/projects/${project_id}/`;
    const response = yield defaultApi(url, "PATCH", details);
    if (mode && mode === "edit") {
      yield call(getProjectDetailsRequest, { payload });
    } else if (getAllProjectList) {
      yield call(getProjectListRequest, { payload: { organization_id, type } });
    } else if (clientDetails) {
      yield call(getClientDetails, {
        payload: { organization_id, client_id: payload.client_id },
      });
    }
    yield put({
      type: UPDATE_PROJECT_REQUEST_SUCCESS,
      payload: { ...payload, data: response, editGeneralDetails },
    });
  } catch (err) {
    yield put({
      type: UPDATE_PROJECT_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* removeClientFromProjectRequest({ payload }) {
  try {
    const { organization_id, project_id, client_id, clientDetails } = payload;
    const url = `${organization_id}/projects/${project_id}/remove-client/`;
    const method = "POST";
    const response = yield defaultApi(url, method);
    if (clientDetails) {
      yield call(getClientDetails, {
        payload: { organization_id, client_id: client_id },
      });
    }
    yield put({
      type: REMOVE_CLIENT_FROM_PROJECT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: REMOVE_CLIENT_FROM_PROJECT_FAILURE,
      payload: err,
    });
  }
}

export function* deleteProjectRequest({ payload }) {
  try {
    const { organization_id, project_id, type } = payload;
    const url = `${organization_id}/projects/${project_id}/`;
    const method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_PROJECT_REQUEST_SUCCESS,
      payload: { data: response },
      projectId: project_id,
    });
    // yield call(getProjectListRequest, { payload: { organization_id, type } });
  } catch (err) {
    yield put({
      type: DELETE_PROJECT_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* organizationMembersListRequest({ payload }) {
  try {
    const { organization_id } = payload;
    const url = `/${organization_id}/members/short/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_ORGANIZATION_MEMBERS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ORGANIZATION_MEMBERS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* addTeamToProjectRequest({ payload }) {
  try {
    const { organization_id, project_id, projectData, ...details } = payload;
    const url = `/${organization_id}/projects/${project_id}/assignees/add-team/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);

    if (payload.getDetails) {
      yield call(getTeamDetailsRequest, { payload });
    } else if (payload.getProjects) {
      yield call(getTeamProjectsListRequest, { payload });
    } else {
      yield call(projectAssigneeListRequest, { payload });
    }
    yield put({
      type: ADD_TEAM_TO_PROJECT_SUCCESS,
      payload: {
        data: response,
        projectData,
        getDetails: payload.getDetails,
        notify: "POST",
      },
    });
  } catch (err) {
    yield put({
      type: ADD_TEAM_TO_PROJECT_FAILURE,
      payload: err,
    });
  }
}

export function* removeTeamFromProjectRequest({ payload }) {
  try {
    const { organization_id, project_id, projectData, ...details } = payload;
    const url = `/${organization_id}/projects/${project_id}/assignees/remove-team/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);

    if (payload.getDetails) {
      yield call(getTeamDetailsRequest, { payload });
    } else if (payload.getProjects) {
      yield call(getTeamProjectsListRequest, { payload });
    } else {
      yield call(projectAssigneeListRequest, { payload });
    }
    yield put({
      type: REMOVE_TEAM_FROM_PROJECT_SUCCESS,
      payload: {
        data: response,
        projectData,
        getDetails: payload.getDetails,
        notify: "POST",
      },
    });
  } catch (err) {
    yield put({
      type: REMOVE_TEAM_FROM_PROJECT_FAILURE,
      payload: err,
    });
  }
}

export function* addProjectAssigneeRequest({ payload }) {
  try {
    const { organization_id, project_id, getMemberData, ...details } = payload;
    const url = `/${organization_id}/projects/${project_id}/assignees/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    if (getMemberData && details.user_id) {
      const memberDetailsPayload = {
        organization_id,
        user_id: details.user_id,
      };
      yield call(getMemberDetails, { payload: memberDetailsPayload });
    } else {
      yield call(projectAssigneeListRequest, { payload });
    }

    yield put({
      type: ADD_PROJECT_ASSIGNEE_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: ADD_PROJECT_ASSIGNEE_FAILURE,
      payload: err,
    });
  }
}

export function* updateProjectAssigneeRequest({ payload }) {
  try {
    const { organization_id, project_id, user_id, ...details } = payload;
    const url = `/${organization_id}/projects/${project_id}/assignees/${user_id}/`;
    const method = "PATCH";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_PROJECT_ASSIGNEE_SUCCESS,
      payload: { data: response, userId: user_id, notify: "PATCH" },
    });
  } catch (err) {
    yield put({
      type: UPDATE_PROJECT_ASSIGNEE_FAILURE,
      payload: err,
    });
  }
}

export function* deleteProjectAssigneeRequest({ payload }) {
  try {
    const { organization_id, project_id, user_id, isFromMemberDetailsPage } = payload;
    const url = `/${organization_id}/projects/${project_id}/assignees/${user_id}/`;
    const method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_PROJECT_ASSIGNEE_SUCCESS,
      payload: { data: response, notify: "DELETE" },
      userData: {
        projectId: project_id,
        userId: user_id,
      },
      isFromMemberDetailsPage
    });
  } catch (err) {
    yield put({
      type: DELETE_PROJECT_ASSIGNEE_FAILURE,
      payload: err,
    });
  }
}

export function* projectAssigneeListRequest({ payload }) {
  try {
    const { organization_id, project_id } = payload;
    const url = `${organization_id}/projects/${project_id}/assignees/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_ASSIGNEE_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_ASSIGNEE_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* projectTaskListRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, project_id, status, user_id, page } = payload;
    const url = `${organization_id}/projects/${project_id}/tasks/${
      status ? `?status=${status}` : ""
    }${
      user_id ? (status ? `&members=${user_id}` : `?members=${user_id}`) : ``
    }${page ? (status || user_id ? `&page=${page}` : `?page=${page}`) : ``}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_TASK_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_TASK_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* createTaskRequest({ payload }) {
  try {
    const {
      organization_id,
      project_id,
      projectName,
      user_id,
      position,
      ...details
    } = payload;
    const url = `${organization_id}/projects/${project_id}/tasks/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    if (user_id && response && response.data && response.data.id) {
      yield fork(addTaskAssigneeRequest, {
        payload: { ...payload, task_id: response.data.id },
      });
    }
    yield put({
      type: PROJECT_ADD_TASK_SUCCESS,
      payload: {
        ...payload,
        data: response.data,
        project: { id: project_id, name: projectName, is_active: true },
        orgId: organization_id,
        userId: user_id,
        position,
        notify: "POST",
      },
    });
  } catch (err) {
    yield put({
      type: PROJECT_ADD_TASK_FAILURE,
      payload: err,
    });
  }
}

export function* editTaskRequest({ payload }) {
  try {
    const { organization_id, project_id, task_id, taskStatus, ...details } =
      payload;
    const url = `${organization_id}/projects/${project_id}/tasks/${task_id}/`;
    const method = "PATCH";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: PROJECT_EDIT_TASK_SUCCESS,
      payload: {
        ...payload,
        data: response,
        taskStatus,
        notify: "PATCH",
      },
    });
  } catch (err) {
    yield put({
      type: PROJECT_EDIT_TASK_FAILURE,
      payload: err,
    });
  }
}

export function* deleteTaskRequest({ payload }) {
  try {
    const { organization_id, project_id, task_id } = payload;
    const url = `${organization_id}/projects/${project_id}/tasks/${task_id}/`;
    const method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: PROJECT_DELETE_TASK_SUCCESS,
      payload: {
        ...payload,
        data: response,
        taskId: task_id,
        notify: "DELETE",
      },
    });
  } catch (err) {
    yield put({
      type: PROJECT_DELETE_TASK_FAILURE,
      payload: err,
    });
  }
}

export function* taskAssigneeListRequest({ payload }) {
  try {
    const { organization_id, project_id, task_id } = payload;
    const url = `${organization_id}/projects/${project_id}/tasks/${task_id}/members/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_TASK_ASSIGNEE_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TASK_ASSIGNEE_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* addTaskAssigneeRequest({ payload }) {
  try {
    const { organization_id, project_id, task_id, ...details } = payload;
    const url = `${organization_id}/projects/${project_id}/tasks/${task_id}/members/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);

    yield put({
      type: ADD_TASK_ASSIGNEE_SUCCESS,
      payload: { data: response.data, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: ADD_TASK_ASSIGNEE_FAILURE,
      payload: err,
    });
  }
}

export function* deleteTaskAssigneeRequest({ payload }) {
  try {
    const { organization_id, project_id, task_id, user_id } = payload;
    const url = `${organization_id}/projects/${project_id}/tasks/${task_id}/members/${user_id}/`;
    const method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_TASK_ASSIGNEE_SUCCESS,
      payload: {
        data: response,
        taskId: task_id,
        userId: user_id,
        notify: "DELETE",
      },
    });
  } catch (err) {
    yield put({
      type: DELETE_TASK_ASSIGNEE_FAILURE,
      payload: err,
    });
  }
}

//Budget and Billing
export function* getProjectBudgetRequest({ payload }) {
  try {
    const { organization_id, project_id } = payload;
    const url = `${organization_id}/projects/${project_id}/budget/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_BUDGET_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_BUDGET_FAILURE,
      payload: err,
    });
  }
}

export function* addProjectBudgetRequest({ payload }) {
  try {
    const { organization_id, project_id, ...details } = payload;
    const url = `${organization_id}/projects/${project_id}/budget/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    // yield call(getProjectBudgetRequest, {
    //   payload: { organization_id, project_id },
    // });
    yield put({
      type: ADD_PROJECT_BUDGET_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: ADD_PROJECT_BUDGET_FAILURE,
      payload: err,
    });
  }
}

export function* updateProjectBudgetRequest({ payload }) {
  try {
    const { organization_id, project_id, ...details } = payload;
    const url = `${organization_id}/projects/${project_id}/budget/`;
    const method = "PUT";
    const response = yield defaultApi(url, method, details);
    // yield call(getProjectBudgetRequest, {
    //   payload: { organization_id, project_id },
    // });
    yield put({
      type: UPDATE_PROJECT_BUDGET_SUCCESS,
      payload: { data: response.data, notify: "PUT" },
    });
  } catch (err) {
    yield put({
      type: UPDATE_PROJECT_BUDGET_FAILURE,
      payload: err,
    });
  }
}

export function* getProjectBillingRequest({ payload }) {
  try {
    const { organization_id, project_id } = payload;
    const url = `${organization_id}/projects/${project_id}/billable/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_BILLING_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_BILLING_FAILURE,
      payload: err,
    });
  }
}

export function* addProjectBillingRequest({ payload }) {
  try {
    const { organization_id, project_id, ...details } = payload;
    const url = `/${organization_id}/projects/${project_id}/billable/`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: ADD_PROJECT_BILLING_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: ADD_PROJECT_BILLING_FAILURE,
      payload: err,
    });
  }
}

export function* updateProjectBillingRequest({ payload }) {
  try {
    const { organization_id, project_id, ...details } = payload;
    const url = `/${organization_id}/projects/${project_id}/billable/`;
    const method = "PUT";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_PROJECT_BILLING_SUCCESS,
      payload: { data: response.data, notify: "PUT" },
    });
  } catch (err) {
    yield put({
      type: UPDATE_PROJECT_BILLING_FAILURE,
      payload: err,
    });
  }
}

export function* getProjectInvoicesRequest({ payload }) {
  try {
    const { organization_id, project_id, page } = payload;
    // const url = `/${organization_id}/invoices/linked-projects/${project_id}/items/`;
    const url = `/${organization_id}/invoices/?project=${project_id}${
      page ? `&page=${page}` : ``
    }`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_PROJECT_INVOICES_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_PROJECT_INVOICES_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    //Get Project
    takeLatest(GET_PROJECT_LIST, getProjectListRequest),
    takeLatest(GET_PROJECT_SHORT_LIST, getProjectShortListRequest),
    takeLatest(GET_PROJECT_DETAILS, getProjectDetailsRequest),
    takeLatest(
      GET_PROJECT_BUDGET_AND_BILLABLE,
      getProjectBudgetAndBillableRequest
    ),
    takeLatest(GET_PROJECT_EXPENSES, getProjectExpensesRequest),
    takeLatest(GET_PROJECT_YEARLY_EXPENSES, getProjectYearlyExpensesRequest),
    //create
    takeLatest(CREATE_PROJECT_REQUEST, createProjectRequest),
    takeLatest(DUPLICATE_PROJECT_REQUEST, duplicateProjectRequest),
    takeLatest(UPDATE_PROJECT_REQUEST, updateProjectRequest),
    takeLatest(REMOVE_CLIENT_FROM_PROJECT, removeClientFromProjectRequest),
    takeLatest(GET_ORGANIZATION_MEMBERS_LIST, organizationMembersListRequest),
    takeLatest(ADD_TEAM_TO_PROJECT, addTeamToProjectRequest),
    takeLatest(REMOVE_TEAM_FROM_PROJECT, removeTeamFromProjectRequest),
    takeLatest(ADD_PROJECT_ASSIGNEE, addProjectAssigneeRequest),
    takeLatest(UPDATE_PROJECT_ASSIGNEE, updateProjectAssigneeRequest),
    takeLatest(DELETE_PROJECT_ASSIGNEE, deleteProjectAssigneeRequest),
    takeLatest(GET_PROJECT_ASSIGNEE_LIST, projectAssigneeListRequest),
    takeLatest(GET_PROJECT_TASK_LIST, projectTaskListRequest),
    takeLatest(PROJECT_ADD_TASK, createTaskRequest),
    takeLatest(PROJECT_EDIT_TASK, editTaskRequest),
    takeLatest(PROJECT_DELETE_TASK, deleteTaskRequest),
    takeLatest(GET_TASK_ASSIGNEE_LIST, taskAssigneeListRequest),
    takeLatest(ADD_TASK_ASSIGNEE, addTaskAssigneeRequest),
    takeLatest(DELETE_TASK_ASSIGNEE, deleteTaskAssigneeRequest),

    //delete
    takeLatest(DELETE_PROJECT_REQUEST, deleteProjectRequest),

    //Budget and Billing
    takeLatest(GET_PROJECT_BUDGET, getProjectBudgetRequest),
    takeLatest(ADD_PROJECT_BUDGET, addProjectBudgetRequest),
    takeLatest(UPDATE_PROJECT_BUDGET, updateProjectBudgetRequest),
    takeLatest(GET_PROJECT_BILLING, getProjectBillingRequest),
    takeLatest(ADD_PROJECT_BILLING, addProjectBillingRequest),
    takeLatest(UPDATE_PROJECT_BILLING, updateProjectBillingRequest),

    //Invoices
    takeLatest(GET_PROJECT_INVOICES, getProjectInvoicesRequest),
  ]);
}
