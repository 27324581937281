import FilterDropDownStyle from "../DropdownStyle/FilterDropDownStyle";
import Select from "react-select";

const CommonDropdown = ({
  options,
  selectedOptions,
  isMulti,
  placeholder,
  style,
  indicatorIconSize,
  menuPlacement,
  clearable,
  handleChange,
}) => {
  return (
    <Select
      isSearchable
      isClearable={clearable}
      isMulti={isMulti}
      menuPlacement={menuPlacement}
      indicatorIconSize={22}
      components={{
        IndicatorSeparator: () => null,
      }}
      placeholder={placeholder || "Select Option"}
      value={selectedOptions}
      options={options}
      onChange={handleChange}
      styles={style ? style : FilterDropDownStyle({ height: "40px" })}
    />
  );
};

export default CommonDropdown;
