import { useState, useEffect } from "react";
import {
  ActivityTableItem,
  TableHead,
  TableItem,
} from "../../../styledComponents/teams";
import {
  CommonGrid,
  CardTitle,
  TableText,
  CommonText,
  IconButton,
} from "../../../styledComponents/common";
import {
  MemberSettingsSection,
  MembersTableHeadContainer,
  MembersTableContainer,
  MemberNameDiv,
  Input,
} from "./membersTableStyles";
import { ToggleButton, ToggleButtonRound } from "./activitySettingsStyles";
import {
  CommImageNameComp,
  CommFirstRoundLetterCompNew,
} from "../../Members/CommonComponents";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";

import searchIcon from "../../../assets/img/icons/search.svg";

import { getFullName } from "../../../utils/helper";
import { IconArrow, ToolTip } from "../../../styledComponents/Activity";
import WarningTooltip from "../../../components/Tooltip/WarningTooltip";

const MembersTable = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [searchTerm, setSearchText] = useState("");

  const {
    history,
    membersListLoading,
    selectedOrganization,
    membersList,
    memberSettingsChange,
    organizationSettings,

    // pagination
    totalMembersCount,
    currentPageNumber,
    onPageChange,
  } = props;

  const { screenshot_track, application_track, url_track, keystroke_track } =
    organizationSettings;

  useEffect(() => {
    if (membersList?.length > 0) {
      const list = [];
      membersList.map((member) => {
        list.push({
          id: member.id,
          userId: member.user.id,
          name: getFullName(member.user),
          avatar: member.user?.avatar ?? null,
          color: member.user?.color ?? null,
          screenshot: member.screenshot_track,
          screenshotNotification: member.show_screenshot_notification,
          url: member.url_track,
          app: member.application_track,
          activity: member.keystroke_track,
        });
      });
      setMemberList(list);
    } else if (membersList?.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      const originalList = JSON.parse(JSON.stringify(membersList));
      if (searchTerm && searchTerm !== "") {
        let filteredList = [];
        originalList.forEach((e) => {
          let searchField;
          searchField = getFullName(e.user);

          if (
            searchTerm &&
            searchField
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase().trim()) === -1
          ) {
            return;
          }
          if (selectedOrganization) {
            filteredList.push({
              id: e.id,
              userId: e.user.id,
              name: getFullName(e.user),
              avatar: e.user?.avatar ?? null,
              color: e.user?.color ?? null,
              screenshot: e.screenshot_track,
              screenshotNotification: e.show_screenshot_notification,
              url: e.url_track,
              app: e.application_track,
              activity: e.keystroke_track,
            });
          }
        });
        setMemberList([...filteredList]);
      } else if (searchTerm === "") {
        const list = [];
        originalList.map((member) => {
          list.push({
            id: member.id,
            userId: member.user.id,
            name: getFullName(member.user),
            avatar: member.user?.avatar ?? null,
            color: member.user?.color ?? null,
            screenshot: member.screenshot_track,
            screenshotNotification: member.show_screenshot_notification,
            url: member.url_track,
            app: member.application_track,
            activity: member.keystroke_track,
          });
        });
        setMemberList([...list]);
      }
    }
  }, [searchTerm, membersList]);

  const onTextChange = (event) => {
    if (event.target.value != null || event.target.value != undefined) {
      setSearchText(event.target.value);
    }
  };
  return (
    <MemberSettingsSection
      margin={
        selectedOrganization &&
        (selectedOrganization.role !== "owner" ||
          selectedOrganization.role !== "admin")
          ? "20px 0 0"
          : "0"
      }
    >
      {membersListLoading ? (
        <ComponentCircleLoader padding="10px 0" />
      ) : memberList && memberList.length > 0 ? (
        <>
          <CommonGrid columns="45% 55%" gap="2em" alignItem="center">
            <CardTitle fontSize="16px" margin="0 0 0 40px">
              Tracking Individual Member
            </CardTitle>
            <Input
              backgroundImage={searchIcon}
              type="text"
              value={searchTerm}
              placeholder="Search by member name..."
              onChange={(e) => onTextChange(e)}
            />
          </CommonGrid>
          {totalMembersCount && totalMembersCount > 0 ? (
            <TopPagination
              itemName="members"
              totalCount={totalMembersCount}
              currentPage={currentPageNumber}
              onPageChange={(page) => onPageChange(page)}
              pageSize={50}
              padding="0 40px"
            />
          ) : null}
          <MembersTableHeadContainer activity gap="2em">
            <TableHead member padding="0 0 20px 0" col="120px 20px">
              <TableText>Member Name</TableText>
            </TableHead>

            <TableHead left padding="0 0 20px 10px">
              <TableText>Screenshot</TableText>
              {!screenshot_track && (
                <WarningTooltip text="Screenshot Tracking is OFF on organization level" />
              )}
            </TableHead>

            <TableHead right padding="0px 25px 0 0">
              <TableText wrap>Screenshot Notification</TableText>
              {!screenshot_track && (
                <WarningTooltip text="Screenshot Tracking is OFF on organization level" />
              )}
            </TableHead>

            <TableHead padding="0 0 20px 0" col="55px 20px">
              <TableText>Activity</TableText>
              {!keystroke_track && (
                <WarningTooltip text="Activity Tracking is OFF on organization level" />
              )}
            </TableHead>

            <TableHead padding="0 0 20px 0" col="35px 20px">
              <TableText>Apps</TableText>
              {!application_track && (
                <WarningTooltip text="Apps Tracking is OFF on organization level" />
              )}
            </TableHead>

            <TableHead padding="0 0 20px 0" col="30px 20px">
              <TableText>URL</TableText>
              {!url_track && (
                <WarningTooltip text="URL Tracking is OFF on organization level" />
              )}
            </TableHead>
          </MembersTableHeadContainer>
          {memberList.map((member, index) => (
            <MembersTableContainer activity key={index} gap="2em">
              <TableItem style={{ cursor: "pointer" }}>
                <MemberNameDiv
                  onClick={() =>
                    member.userId &&
                    history.push(`/user/member-details?id=${member.userId}`)
                  }
                >
                  <CommImageNameComp
                    imgSource={member.avatar}
                    name={member.name}
                    color={member.color}
                    index={index}
                    size="36px"
                    hoverUnderline
                  />
                </MemberNameDiv>
              </TableItem>
              <ActivityTableItem
                left
                padding="15px 0 15px 10px"
                active={screenshot_track}
              >
                <CommonGrid
                  columns="36px 30px"
                  gap="0.25em"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() =>
                      screenshot_track
                        ? memberSettingsChange("screenshot", member)
                        : undefined
                    }
                    active={screenshot_track && member.screenshot}
                    disabled={!screenshot_track}
                  >
                    <ToggleButtonRound active={member.screenshot} />
                  </ToggleButton>
                  <CommonText
                    $label={!member.screenshot}
                    fontWeight="500"
                    fontSize="13px"
                    margin="0"
                  >
                    {member.screenshot ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid>
              </ActivityTableItem>
              <ActivityTableItem
                right
                padding="15px 0"
                active={screenshot_track}
              >
                <CommonGrid
                  columns="36px 30px"
                  gap="0.25em"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() =>
                      screenshot_track
                        ? memberSettingsChange("screenshotNotification", member)
                        : undefined
                    }
                    active={screenshot_track && member.screenshotNotification}
                    disabled={!screenshot_track}
                  >
                    <ToggleButtonRound active={member.screenshotNotification} />
                  </ToggleButton>
                  <CommonText
                    $label={!member.screenshotNotification}
                    fontWeight="500"
                    fontSize="13px"
                    margin="0"
                  >
                    {member.screenshotNotification ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid>
              </ActivityTableItem>
              <ActivityTableItem active={keystroke_track}>
                <CommonGrid
                  columns="36px 30px"
                  gap="0.25em"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() =>
                      keystroke_track
                        ? memberSettingsChange("activity", member)
                        : undefined
                    }
                    active={keystroke_track && member.activity}
                    disabled={!keystroke_track}
                  >
                    <ToggleButtonRound active={member.activity} />
                  </ToggleButton>
                  <CommonText
                    $label={!member.activity}
                    fontWeight="500"
                    fontSize="13px"
                    margin="0"
                  >
                    {member.activity ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid>
              </ActivityTableItem>
              <ActivityTableItem active={application_track}>
                <CommonGrid
                  columns="36px 30px"
                  gap="0.25em"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() =>
                      application_track
                        ? memberSettingsChange("app", member)
                        : undefined
                    }
                    active={application_track && member.app}
                    disabled={!application_track}
                  >
                    <ToggleButtonRound active={member.app} />
                  </ToggleButton>
                  <CommonText
                    $label={!member.app}
                    fontWeight="500"
                    fontSize="13px"
                    margin="0"
                  >
                    {member.app ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid>
              </ActivityTableItem>
              <ActivityTableItem active={url_track}>
                <CommonGrid
                  columns="36px 30px"
                  gap="0.25em"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() =>
                      url_track
                        ? memberSettingsChange("url", member)
                        : undefined
                    }
                    active={url_track && member.url}
                    disabled={!url_track}
                  >
                    <ToggleButtonRound active={member.url} />
                  </ToggleButton>
                  <CommonText
                    $label={!member.url}
                    fontWeight="500"
                    fontSize="13px"
                    margin="0"
                  >
                    {member.url ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid>
              </ActivityTableItem>
            </MembersTableContainer>
          ))}
          {totalMembersCount && totalMembersCount > 0 ? (
            <BottomPagination
              totalCount={totalMembersCount}
              currentPage={currentPageNumber}
              onPageChange={(page) => onPageChange(page)}
              pageSize={50}
            />
          ) : null}
        </>
      ) : memberList && memberList.length === 0 && searchTerm ? (
        <>
          <CommonGrid columns="45% 55%" gap="2em" alignItem="center">
            <CardTitle fontSize="16px" margin="0 0 0 40px">
              Tracking Individual Member
            </CardTitle>
            <Input
              backgroundImage={searchIcon}
              type="text"
              value={searchTerm}
              placeholder="Search by member name..."
              onChange={(e) => onTextChange(e)}
            />
          </CommonGrid>
          <NoDataComponent
            title="No member found with this name"
            padding="30px 0"
            imageHeight="130px"
            imageWidth="130px"
            titleFontSize="16px"
          />
        </>
      ) : null}
    </MemberSettingsSection>
  );
};

export default MembersTable;
